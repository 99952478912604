///////////////////////////// CUSTOM SELECT /////////////////////////////

// http://jsfiddle.net/v363gkj6/1/
// Iterate over each select element
$('select').each(function () {

    // Cache the number of options
    var $this = $(this),
        numberOfOptions = $(this).children('option').length;

    // Hides the select element
    $this.addClass('s-hidden');

    // Wrap the select element in a div
    $this.wrap('<div class="select"></div>');

    // Insert a styled div to sit over the top of the hidden select element
    $this.after('<div class="styledSelect"></div>');

    // Cache the styled div
    var $styledSelect = $this.next('div.styledSelect');

    // Show the first select option in the styled div
    $styledSelect.text($this.children('option[selected]').text());

    // Insert an unordered list after the styled div and also cache the list
    var $list = $('<ul />', {
        'class': 'options'
    }).insertAfter($styledSelect);

    // Insert a list item into the unordered list for each select option
    for (var i = 0; i < numberOfOptions; i++) {
        $('<li />', {
            text: $this.children('option').eq(i).text(),
            rel: $this.children('option').eq(i).val()
        }).appendTo($list);
    }

    // Cache the list items
    var $listItems = $list.children('li');

    // Show the unordered list when the styled div is clicked (also hides it if the div is clicked again)
    $styledSelect.click(function (e) {
        e.stopPropagation();
        if ($(this).hasClass('active')) {
            $(this).removeClass('active').next('ul.options').hide();
        } else {
            $('div.styledSelect.active').each(function () {
                $(this).removeClass('active').next('ul.options').hide();
            });
            $(this).toggleClass('active').next('ul.options').toggle();
        }
    });

    // Hides the unordered list when a list item is clicked and updates the styled div to show the selected list item
    // Updates the select element to have the value of the equivalent option
    $listItems.click(function (e) {
        e.stopPropagation();
        $styledSelect.text($(this).text()).removeClass('active');
        $this.val($(this).attr('rel'));
        $this.change();
        $list.hide();
        /* alert($this.val()); Uncomment this for demonstration! */
    });

    // Hides the unordered list when clicking outside of it
    $(document).click(function () {
        $styledSelect.removeClass('active');
        $list.hide();
    });

});
/////////////////////////// END CUSTOM SELECT ///////////////////////////

////////////////////////////// INPUT GROUP //////////////////////////////
$('.form-group').each(function () {
    var $input = $(this).find('input'),
        $label = $(this).children('label');

    moveLabelToTop($input, $label)
});

$('.input-group').each(function () {
    var $this = $(this),
        $input = $(this).children('input'),
        $label = $(this).children('label'),
        $inputPostpendCount = $(this).children('.input-postpend').length,
        $inputPostpendPassword = $($input).parent().children('.input-group-append').children('.input-group-showpassword');

    if ($inputPostpendCount > 1) {
        $input.css("padding-right", "80px");
        $inputPostpendPassword.css("right", "40px");
    }

    moveLabelToTop($input, $label)

    $input.on("keydown keyup paste change", function (e) {
        if ($input.val()) {
            $this.removeClass("input-postpend-hidden");
            $inputPostpendPassword.removeClass("d-none");
        } else {
            $this.addClass("input-postpend-hidden");
            $inputPostpendPassword.addClass("d-none");
        }
    });

    $inputPostpendPassword.click(function (e) {
        e.stopPropagation();
        if ($input.attr('type') == "password") {
            $input.attr('type', "text");
            $(this).addClass('show-password');
        } else {
            $input.attr('type', "password");
            $(this).removeClass('show-password');
        }
    });
});

function moveLabelToTop($input, $label) {
    $($input).parent().children('.input-group-append').children('.input-group-clear').click(function () {
        $input.val("");
    });
    $input.on("focusin", function (e) {
        $label.addClass("to-top");
        $($input).parent().addClass("active");

    });
    $input.on("focusout", function (e) {
        if (!$input.val()) {
            $label.removeClass("to-top");
        }
        setTimeout(function () {
            $($input).parent().removeClass("active");
        }, 500);
    });
    if ($input.val()) {
        $label.addClass("to-top");
    }
}

(function () {
    'use strict';
    window.addEventListener('load', function () {
// Fetch all the forms we want to apply custom Bootstrap validation styles to
        var forms = document.getElementsByClassName('needs-validation');
// Loop over them and prevent submission
        var validation = Array.prototype.filter.call(forms, function (form) {
            form.addEventListener('submit', function (event) {
                if (form.checkValidity() === false) {
                    event.preventDefault();
                    event.stopPropagation();
                }
                form.classList.add('was-validated');
                // $('.input-group-clear').css("right", '32px');
                // $('.input-group-showpassword').css("right", '55px');
            }, false);
            form.checkValidity();
        });
    }, false);
})();
//////////////////////////// END INPUT GROUP ////////////////////////////
