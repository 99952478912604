let jeniot = {};

//alert("test");
jeniot.init = function() {
  //alert("init");
  articleSearchMobileCategoriesClick();
  showcaseAppAnimation();
  showcaseAppAnimationMobile();
  carouselOptions();
  textHoverCardButton();
  textHoverCatShowcase();
  createClassActive(".content-showcase__content-switcher--category--box");
  toggleTooltip();
  swiperSliderParam();
  // setHeight();
  truncateText(
    ".suggested-articles__content--description.ellipsis",
    ".suggested-articles__content--description.ellipsis > a > h3"
  );
  swiperQuotesCarousel();
  swiperEcommerceCardGrid2();
  swiperEcommerceCardGrid4();
  swiperIndicatorCarousel();
  swiperComparativeTableMobile();
  swiperWhatTheySay();
  equalHeight(".card-buttons__card .sameHeight");
  equalHeight(".ecommerce-card__boxcard--title > .sameHeight2");
  equalHeight(".ecommerce-card__boxcard--title > .sameHeight3");
  equalHeight(".ecommerce-card__boxcard--desc > .sameHeight4");
  equalHeight(".grid-section__block-title > .sameHeight5");
  equalHeight(".grid-section__block-desc > .sameHeight6");
  generaliVideo();
  showMore();
  focusSearch();
  autoFormSearch();
  addPlaceholder();
  carouselLanding();
  delPlaceholder();
  overflowBodyMobile();
  showResetForm();
  switchSlide();
  showcaseIllustration();
  businessIllustration();
  swiperSliderBusiness();
  swiperCard();
  swiperCardShowcase();
  swiperSelectCard();
};

// FUNCTION -----------------------------

$(".custom-arrow-collapse").click(function(){
  $(this).toggleClass("down")  ; 
 })

//Function Business: create ID element on container animation
function swiperSliderBusiness() {
  if ($("#slider-icons-business").length) {
    var siObject2 = new Swiper("#slider-icons-business", {
      spaceBetween: 500,
      slidesPerView: 5,
      freeMode: true,
      loopedSlides: 5, //looped slides should be the same
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      watchOverflow: true
    });
  }
  if ($("#slider-param-business").length) {
    var siObject3 = new Swiper("#slider-param-business", {
      spaceBetween: 550,
      effect: "fade",
      fadeEffect: {
        crossFade: true
      },
      loopedSlides: 5, //looped slides should be the same
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      thumbs: {
        swiper: siObject2
      }
    });
    siObject3.on("slideChange", function() {
    });
  }
}


function businessIllustration() {

  if($("main").hasClass("business-landing-page")){

    // console.log("business-landing-page")

    $(".link-cat").each(function(i, element) {
      $(element).attr("href", `#link-cat-${i}`);
      $(element).attr("id", `link-${i}`);
    });
  
    $(".box__iconsmall").each(function(i, element) {
      $(element).attr("id", `link-${i}`);
    });
  
    $(".content-business__business-illustration--animation").each(function(
      i,
      element
    ) {
      $(element).attr("id", `sh-ill-${i}`);
    });
  
    $(".cat-block").each(function(i, element) {
      $(element).attr("id", `cat-elem-${i}`);
    });
  
    $(".link-cat").click(function(event, categorybusiness) {
    
      var anchor = event.target.getAttribute("href").replace("#", "");
  
      if ($(this).hasClass("unselect-cat")) {
        var index = anchor.replace("link-cat-", "");
  
        $(this)
          .closest(".content-business")
          .find(".content-business__business-illustration--animation")
          .removeClass("show")
          .addClass("hide");
  
        $(`.block-animation-${index}`)
          .removeClass("show")
          .addClass("hide");
        $(`#sh-ill-${index}`)
          .removeClass("hide")
          .addClass("show");
        $(".link-cat")
          .removeClass("select-cat")
          .addClass("unselect-cat");
        $(this)
          .removeClass("unselect-cat")
          .addClass("select-cat");
  
        var editSlideWrapper = $("#appendSlide");
        editSlideWrapper.children().remove();
  
        var categorybusiness = "";
        var categorybusiness = $(`#cat-elem-${index}`).html();
  
        $(".cardLinkMobile").each(function(i, element) {
          $(element).attr("id", ``);
        });
  
        if (
          $(".content-business__business-illustration--animation").hasClass(
            "show"
          )
        ) {
          $(`#sh-ill-${index} .cardLinkMobile`).each(function(i, element) {
            $(element).attr("id", `clm-${i}`);
          });
        }
      } else {
        scrollToAnchor(anchor);
      }
    });
  
    $(".box__iconsmall").click(function(event, categorybusiness) {
      event.preventDefault();
      var anchor = this.getAttribute("id");
  
      if ($(this).hasClass("swiper-slide-thumb-active")) {
        var index = anchor.replace("link-", "");
  
        $(this)
          .closest(".content-business")
          .find(".content-business__business-illustration--animation")
          .removeClass("show")
          .addClass("hide");
  
        $(`.block-animation-${index}`)
          .removeClass("show")
          .addClass("hide");
        $(`#sh-ill-${index}`)
          .removeClass("hide")
          .addClass("show");
        $(".box__iconsmall")
          .removeClass("select-cat")
          .addClass("unselect-cat");
        $(this)
          .removeClass("unselect-cat")
          .addClass("select-cat");
  
        var editSlideWrapper = $("#appendSlide");
        editSlideWrapper.children().remove();
  
        var categorybusiness = "";
        categorybusiness = $(`#cat-elem-${index}`).html();
  
        $(".cardLinkMobile").each(function(i, element) {
          $(element).attr("id", ``);
        });
  
        if (
          $(".content-business__business-illustration--animation").hasClass(
            "show"
          )
        ) {
          $(`#sh-ill-${index} .cardLinkMobile`).each(function(i, element) {
            $(element).attr("id", `clm-${i}`);
          });
        }
      } else {
      }
    });
    // $(".swiper--arrow--nav > span").click(function(event, categorybusiness) {
    //   var getIdLink = $(this)
    //     .closest(".content-business__content-switcher--category--box")
    //     .find(".swiper-slide-thumb-active")
    //     .attr("id")
    //     .replace("link-", "");
    //   var setLink = "";
    //   var index = 0;
    //   //console.log(getIdLink);
    //   if ($(this).hasClass("sl-next")) {
    //     index = parseInt(getIdLink) + 1;
    //     setLink = "success";
    //   } else if ($(this).hasClass("sl-prev")) {
    //     index = parseInt(getIdLink) - 1;
    //     setLink = "success";
    //   }
    //   if (setLink == "success") {
    //     $(this)
    //       .closest(".content-business")
    //       .find(".content-business__business-illustration--animation")
    //       .removeClass("show")
    //       .addClass("hide");
  
    //     $(`.block-animation-${index}`)
    //       .removeClass("show")
    //       .addClass("hide");
    //     $(`#sh-ill-${index}`)
    //       .removeClass("hide")
    //       .addClass("show");
    //     $(this)
    //       .closest(".content-business__content-switcher--category--box")
    //       .find(".box__iconsmall")
    //       .removeClass("select-cat")
    //       .addClass("unselect-cat");
    //     $(`#link-${index}`)
    //       .removeClass("unselect-cat")
    //       .addClass("select-cat");
  
    //     var editSlideWrapper = $("#appendSlide");
    //     editSlideWrapper.children().remove();
  
    //     $(".cardLinkMobile").each(function(i, element) {
    //       $(element).attr("id", ``);
    //     });
  
    //     if (
    //       $(".content-business__business-illustration--animation").hasClass(
    //         "show"
    //       )
    //     ) {
    //       $(`.block-animation-${index + 1} .cardLinkMobile`).each(function(
    //         i,
    //         element
    //       ) {
    //         $(element).attr("id", `clm-${i}`);
    //       });
    //     }
    //   } else {
    //   }
    // });

  }

}

function showMore() {
  $(".showMore").click(function() {
    $(this)
      .closest("div")
      .hide();
    $(".more-results")
      .slideDown(600)
      .show();
  });
}

$("#button").click(function() {
  $("#subscribe").toggle();
  $("#newsletter-form").hide();
});

function addPlaceholder() {
  if ($(window).width() < 765) {
    $("#input-newsletter").attr("placeholder", "Inserisci la tua mail");
  } else {
    $("#input-newsletter").attr("placeholder", "jeniot.ipsum@email.com");
  }
}

function delPlaceholder() {
  $("input").click(function() {
    var placeholder = $(this).attr("placeholder");
    $(this).attr("placeholder", "");
    //alert(placeholder);
    resetPlaceholder(placeholder);
  });
  function resetPlaceholder(placeholder) {
    $("input").focusout(function() {
      $(this).attr("placeholder", placeholder);
    });
  }
}

$("#standardCheckbox").click(function() {
  $("input:checkbox")
    .not(this)
    .prop("checked", this.checked);
});


$(".checkbox-container .custom-checkbox").click(function() {
  let i = $(this).find(".custom-control-input");
  if(i.attr("checked"))
    i.attr('checked', false); 
  else
    i.attr("checked", "checked");
});


// Generali Video
function generaliVideo() {
  $(".generali-video button").click(function() {
    var mediaVideo = $(this)
      .closest(".generali-video")
      .find("video")
      .get(0);
    var _mediaVideo = $(this)
      .closest(".generali-video")
      .find("video");

    // FadeOut .generali-video__btn
    $(this)
      .closest(".generali-video__btn")
      .fadeOut("fast");
    // Add controls to video
    mediaVideo.setAttribute("controls", "controls");

    if (mediaVideo.paused) {
      mediaVideo.play();
      mediaVideo.setAttribute("controls", "controls");
    } else {
      mediaVideo.pause();
      mediaVideo.removeAttribute("controls");
    }
    // Reload video and poster
    _mediaVideo.on("ended", function() {
      mediaVideo.load();
      $(this).removeAttr("controls");
      $(this)
        .closest(".generali-video")
        .find(".generali-video__btn")
        .fadeIn("fast");
    });
  });
}

// Article search mobile categories onClick
function articleSearchMobileCategoriesClick() {
  $(".article-search-categories-mobile-filters-text").click(function() {
    var cross = $(this)
      .closest(".article-search-categories-mobile-filters")
      .find(".article-search-categories-mobile-filters-cross");
    var text = $(this);
    if (cross.hasClass("hide")) cross.removeClass("hide").addClass("show");
    if (!text.hasClass("selected")) text.addClass("selected");
  });

  $(".article-search-categories-mobile-filters-cross").click(function() {
    var cross = $(this);
    var text = $(this)
      .closest(".article-search-categories-mobile-filters")
      .find(".article-search-categories-mobile-filters-text");
    if (cross.hasClass("show")) cross.removeClass("show").addClass("hide");
    if (text.hasClass("selected")) text.removeClass("selected");
  });
}

// Showcase App Animation

function showcaseAppAnimation() {
  $(".showcase-app").each(function(index) {
    var list_index = 0;
    let list = $(this).find(".list");
    let sideimg = $(this).find(".side-image");
    setInterval(function() {
      if (list_index + 1 < list.length) {
        list_index++;
      } else {
        list_index = 0;
      }
      list.each(function(index, val) {
        if (index !== list_index) {
          $(val).removeClass("add-animation");
        } else {
          $(val).addClass("add-animation");
        }
      });
      sideimg.each(function(index, val) {
        if (index !== list_index) {
          $(val).removeClass("show");
        } else {
          $(val).addClass("show");
        }
      });
    }, 4500);
  });
}

function showcaseAppAnimationMobile() {
  $(".showcase-app").each(function(index) {
    var gauge_index = 0;

    var g1 = $(this).find("#gauge1");

    if (g1.hasClass("gauge add-animation")) {
      g1.attr("percent", 100);
    } else {
      g1.attr("percent", 0);
    }

    var g = $(this).find(".gauge");

    setInterval(function() {
      if (gauge_index + 1 < g.length) {
        gauge_index++;
      } else {
        gauge_index = 0;
      }

      g.each(function(index, val) {
        if (index !== gauge_index) {
          $(val).removeClass("add-animation");
        } else {
          $(val).addClass("add-animation");
        }

        if (val.getAttribute("class") === "gauge add-animation") {
          $(val).attr("percent", 100);
        } else {
          $(val).attr("percent", 0);
        }
      });
    }, 4500);
  });
}

// Searchbox dropdown with show/hide event
$("#searchbox").on("focusin", function() {
  $(".help-dropdown").removeClass("hide");
});

$("#searchbox").on("focusout", function() {
  setTimeout(function() {
    $(".help-dropdown").addClass("hide");
  }, 1000);
});

// this function must be launched after retrieving the data from REST
var oldSearch;
$("#searchbox").on("keyup", function() {
  if (oldSearch !== undefined) {
    $(".dropdown-text").unhighlight({ element: "b", className: "important" });
  }
  $(".dropdown-text").highlight(
    $("#searchbox")
      .val()
      .split(" "),
    { element: "b", className: "important" }
  );
  oldSearch = $("#searchbox").val();
});

// Functions for Hero Banner Carousel
function carouselOptions() {
  $(".carousel").carousel({
    interval: 8000,
    pause: false
  });
}
// Functions for Landing Carousel
function carouselLanding() {
  $(".landing").carousel({
    interval: 5000,
    pause: true
  });
}

function switchSlide(sliderIndex, carouselIndex) {
  switch (carouselIndex) {
    case 0:
      $(".landing.landing_one").carousel(sliderIndex);
      break;
    case 1:
      $(".landing.landing_two").carousel(sliderIndex);
      break;
  }
}

// Function hover text violet on second component HP
function textHoverCardButton() {
  $(".card-buttons__card a").hover(
    function() {
      $(this)
        .closest(".card-buttons__card")
        .find(".card-buttons__card--title")
        .addClass("violet-text");
    },
    function() {
      $(this)
        .closest(".card-buttons__card")
        .find(".card-buttons__card--title")
        .removeClass("violet-text");
    }
  );
}

// Function hover text violet Content-switcher component
function textHoverCatShowcase() {
  $(".content-showcase__content-switcher--category--box a").hover(
    function() {
      $(this)
        .closest(".content-showcase__content-switcher--category--box")
        .find(".box__title")
        .addClass("violet-text");
      $(this)
        .closest(".content-showcase__content-switcher--category--box")
        .find(".box__icon svg g g path")
        .css("stroke", "#4225B0");
    },
    function() {
      $(this)
        .closest(".content-showcase__content-switcher--category--box")
        .find(".box__title")
        .removeClass("violet-text");
      $(this)
        .closest(".content-showcase__content-switcher--category--box")
        .find(".box__icon svg g g path")
        .css("stroke", "#706F6F");
    }
  );
}

// Function create class "active"
function createClassActive(element) {
  $(element).click(function() {
    $(element).removeClass("active");
    $(this).addClass("active");
  });
}

// Function row with "sameHeight"
function equalHeight(sameHeightEl) {
  if (sameHeightEl) {
    let maxHeight = -1;
    $(sameHeightEl).each(function() {
      $(this).removeAttr("style");
      maxHeight = maxHeight > $(this).height() ? maxHeight : $(this).height();
    });
    $(sameHeightEl).each(function() {
      $(this).height(maxHeight);
    });
  }
}

// Function Truncate with Ellipsis
function truncateText(containerTruncate, parTruncate) {
  var textContainerHeight = $(containerTruncate).height();

  $(parTruncate).each(function() {
    var $ellipsisText = $(this);

    while ($ellipsisText.outerHeight(true) > textContainerHeight) {
      $ellipsisText.text(function(index, text) {
        return text.replace(/\W*\s(\S)*$/, "...");
      });
    }
  });
}

// Function Swiper: Quotes-carousel
function swiperQuotesCarousel() {
  if ($(".quotes-carousel .swiper-container").length) {
    const quoteCarouselObject = new Swiper(
      ".quotes-carousel .swiper-container",
      {
        spaceBetween: 0,
        slidesPerView: "auto",
        watchOverflow: true,
        centeredSlides: true,
        pagination: {
          el: ".swiper-pagination",
          dynamicBullets: true,
          clickable: true
        },
        navigation: {
          nextEl: ".s2next",
          prevEl: ".s2prev"
        },
        breakpoints: {
          1439: {
            slidesPerView: "auto",
            spaceBetween: 0
          },
          989: {
            slidesPerView: "auto",
            spaceBetween: 0
          },
          767: {
            slidesPerView: "auto",
            spaceBetween: 0
          }
        }
      }
    );
    if (screen.width < 768) {
      quoteCarouselObject.slideTo(0, false);
    } else {
      quoteCarouselObject.slideTo(1, false);
    }
  }
}

// Function Swiper: E-commerce Grid-2
function swiperEcommerceCardGrid2() {
  if ($(".ecommerce-card.grid-2 .swiper-container").length) {
    const ecommCardsObject2 = new Swiper(
      ".ecommerce-card.grid-2 .swiper-container",
      {
        spaceBetween: 24,
        slidesPerView: 2,
        watchOverflow: true,
        pagination: {
          el: ".swiper-pagination",
          dynamicBullets: true,
          clickable: true
        },
        navigation: {
          nextEl: ".grid2Next.s3next",
          prevEl: ".grid2Prev.s3prev"
        },
        breakpoints: {
          1439: {
            slidesPerView: 2,
            spaceBetween: 24
          },
          989: {
            slidesPerView: 2,
            spaceBetween: 13
          },
          767: {
            slidesPerView: 1,
            spaceBetween: 30
            //centeredSlides: true
          }
        }
      }
    );
    if (screen.width < 768) {
      ecommCardsObject2.slideTo(0, false);
    }
  }
}

// Function Swiper: E-commerce Grid-4
function swiperEcommerceCardGrid4() {
  if ($(".ecommerce-card.grid-4 .swiper-container").length) {
    const ecommCardsObject4 = new Swiper(
      ".ecommerce-card.grid-4 .swiper-container",
      {
        spaceBetween: 24,
        slidesPerView: 4,
        watchOverflow: true,
        pagination: {
          el: ".swiper-pagination",
          dynamicBullets: true,
          clickable: true
        },
        navigation: {
          nextEl: ".grid4Next.s3next",
          prevEl: ".grid4Prev.s3prev"
        },
        breakpoints: {
          1439: {
            slidesPerView: 4,
            spaceBetween: 24
          },
          989: {
            slidesPerView: 4,
            spaceBetween: 13
          },
          767: {
            slidesPerView: "auto",
            spaceBetween: 30
            //centeredSlides: true
          }
        }
      }
    );
    if (screen.width < 768) {
      ecommCardsObject4.slideTo(0, false);
    }
  }
}

// Function Swiper: E-commerce Grid-2
function swiperIndicatorCarousel() {
  if ($(".indicator-carousel .swiper-container").length) {
    const icObject = new Swiper(".indicator-carousel .swiper-container", {
      spaceBetween: 15,
      slidesPerView: 1,
      watchOverflow: true,
      noSwipingClass: "noswipe",
      pagination: {
        el: ".swiper-pagination",
        dynamicBullets: true,
        clickable: true
      },
      navigation: {
        nextEl: ".icNext.s3next",
        prevEl: ".icPrev.s3prev"
      },
      breakpoints: {
        1439: {
          slidesPerView: 1,
          spaceBetween: 15
        },
        989: {
          slidesPerView: 1,
          spaceBetween: 15
        },
        767: {
          slidesPerView: 1,
          spaceBetween: 15
        }
      }
    });
  }
}

// Function Swiper: Comparative table
function swiperComparativeTableMobile() {
  if ($(".comparative-table .swiper-container").length) {
    const comparativeTable = new Swiper(
      ".comparative-table .swiper-container",
      {
        spaceBetween: 15,
        slidesPerView: 1,
        watchOverflow: true,
        noSwipingClass: "noswipe",
        pagination: {
          el: ".swiper-pagination",
          dynamicBullets: true,
          clickable: true
        },
        navigation: {
          nextEl: ".ctNext.s3next",
          prevEl: ".ctPrev.s3prev"
        }
      }
    );
    // if (screen.width < 768) {
    //   whatTheySay.slideTo(0, false);
    // } else {
    //   whatTheySay.slideTo(1, false);
    // }
    //test(comparativeTable);
  }
}

function swiperWhatTheySay() {
  let whatTheySaySliders = [];
  $(".what-they-say .swiper-container").each(function(index, element){
      $(this).addClass('swp'+index);

      let whatTheySay = new Swiper('.swp'+index, {
        spaceBetween: 24,
        slidesPerView: 3,
        watchOverflow: true,
        centeredSlides: true,
        pagination: {
          el: ".swiper-pagination",
          dynamicBullets: true,
          clickable: true
        },
        navigation: {
          nextEl: ".wtsNext",
          prevEl: ".wtsPrev"
        },
        breakpoints: {
          1439: {
            slidesPerView: 3,
            spaceBetween: 24
          },
          989: {
            slidesPerView: 3,
            spaceBetween: 12
          },
          767: {
            slidesPerView: "auto",
            spaceBetween: 24
          }
        }
      })
      if (screen.width < 768) {
        whatTheySay.slideTo(0, false);
      } else {
        whatTheySay.slideTo(1, false);
      }
    });
}

//Function toggle tooltip
function toggleTooltip() {
  $(".showcase-tooltip--box-title").click(function() {
    var desc = $(this)
      .closest(".showcase-tooltip--box")
      .find(".showcase-tooltip--box-desc");
    $(".showcase-tooltip--box-desc")
      .not(desc)
      .slideUp();
    desc.promise().done(function() {
      if (desc.is(":animated")) {
        return;
      }
      if (desc.is(":visible")) {
        desc.slideUp();
      } else {
        desc.slideDown();
      }
    });
  });
}

//Function Showcase: create ID element on container animation
function showcaseIllustration() {

  if($("main").hasClass("prodotti")){

    //console.log("prodotti")

    $(".link-cat").each(function(i, element) {
      $(element).attr("href", `#link-cat-${i}`);
      $(element).attr("id", `link-${i}`);
    });
  
    $(".box__iconsmall").each(function(i, element) {
      //$(element).attr("href", `#link-cat-${i}`);
      $(element).attr("id", `link-${i}`);
    });
  
    $(".anchor-cat").each(function(i, element) {
      //$(element).attr("name", `link-cat-${i}`);
    });
  
    $(".content-showcase__showcase-illustration--animation").each(function(
      i,
      element
    ) {
      $(element).attr("id", `sh-ill-${i}`);
    });
  
    $(".cat-block").each(function(i, element) {
      $(element).attr("id", `cat-elem-${i}`);
    });

    let linkCatCliked = -1;
  
    $(".link-cat").click(function(event, categoryShowcase) {

      $(".content-showcase__showcase-illustration--cardMobile .swiper-external-wrapper").addClass("hide");

      //event.preventDefault();
      var anchor = event.target.getAttribute("href").replace("#", "");
  
      if ($(this).hasClass("unselect-cat")) {
        //scrollToAnchor(anchor);
        var index = anchor.replace("link-cat-", "");

        linkCatCliked = index;
  
        $(this)
          .closest(".content-showcase")
          .find(".content-showcase__showcase-illustration--animation")
          .removeClass("show")
          .addClass("hide");
  
        $(`.block-animation-${index}`)
          .removeClass("show")
          .addClass("hide");
        $(`#sh-ill-${index}`)
          .removeClass("hide")
          .addClass("show");
        $(".link-cat")
          .removeClass("select-cat")
          .addClass("unselect-cat");
        $(this)
          .removeClass("unselect-cat")
          .addClass("select-cat");
  
        var editSlideWrapper = $("#appendSlide");
        editSlideWrapper.children().remove();
  
        var categoryShowcase = "";
        var categoryShowcase = $(`#cat-elem-${index}`).html();
  
        //console.log(categoryShowcase);
  
        $(".cardLinkMobile").each(function(i, element) {
          $(element).attr("id", ``);
        });
  
        if (
          $(".content-showcase__showcase-illustration--animation").hasClass(
            "show"
          )
        ) {
          $(`#sh-ill-${index} .cardLinkMobile`).each(function(i, element) {
            $(element).attr("id", `clm-${i}`);
          });
        }
      } else {
        //event.preventDefault();
        scrollToAnchor(anchor);
      }
    });
  



    $(".content-showcase__showcase-illustration--cardMobile .swiper-external-wrapper").addClass("hide");

    $(".cardLinkMobile").on("click", function(){
      // console.log(".cardLinkMobile");

      if(linkCatCliked != -1){
        $(".content-showcase__showcase-illustration--cardMobile .swiper-external-wrapper").addClass("hide");
        $(".content-showcase__showcase-illustration--cardMobile .swiper-external-wrapper").eq(linkCatCliked).removeClass("hide");
      }

    });

    
    $(".box__iconsmall").click(function(event, categoryShowcase) {
      event.preventDefault();
  
      var anchor = this.getAttribute("id");
      //console.log(anchor);
  
      if ($(this).hasClass("swiper-slide-thumb-active")) {
        //scrollToAnchor(anchor);
        var index = anchor.replace("link-", "");
  
        $(this)
          .closest(".content-showcase")
          .find(".content-showcase__showcase-illustration--animation")
          .removeClass("show")
          .addClass("hide");
  
        $(`.block-animation-${index}`)
          .removeClass("show")
          .addClass("hide");
        $(`#sh-ill-${index}`)
          .removeClass("hide")
          .addClass("show");
        $(".box__iconsmall")
          .removeClass("select-cat")
          .addClass("unselect-cat");
        $(this)
          .removeClass("unselect-cat")
          .addClass("select-cat");
  
        var editSlideWrapper = $("#appendSlide");
        editSlideWrapper.children().remove();
  
        var categoryShowcase = "";
        categoryShowcase = $(`#cat-elem-${index}`).html();
  
        //console.log(categoryShowcase);
  
        $(".cardLinkMobile").each(function(i, element) {
          $(element).attr("id", ``);
        });
  
        if (
          $(".content-showcase__showcase-illustration--animation").hasClass(
            "show"
          )
        ) {
          $(`#sh-ill-${index} .cardLinkMobile`).each(function(i, element) {
            $(element).attr("id", `clm-${i}`);
          });
        }
      } else {
        //event.preventDefault();
        //scrollToAnchor(anchor);
      }
    });
    // $(".swiper--arrow--nav > span").click(function(event, categoryShowcase) {
    //   var getIdLink = $(this)
    //     .closest(".content-showcase__content-switcher--category--box")
    //     .find(".swiper-slide-thumb-active")
    //     .attr("id")
    //     .replace("link-", "");
    //   var setLink = "";
    //   var index = 0;
    //   //console.log(getIdLink);
    //   if ($(this).hasClass("sl-next")) {
    //     index = parseInt(getIdLink) + 1;
    //     setLink = "success";
    //   } else if ($(this).hasClass("sl-prev")) {
    //     index = parseInt(getIdLink) - 1;
    //     setLink = "success";
    //   }
    //   //console.log(setIdLink);
    //   if (setLink == "success") {
    //     $(this)
    //       .closest(".content-showcase")
    //       .find(".content-showcase__showcase-illustration--animation")
    //       .removeClass("show")
    //       .addClass("hide");
  
    //     $(`.block-animation-${index}`)
    //       .removeClass("show")
    //       .addClass("hide");
    //     $(`#sh-ill-${index}`)
    //       .removeClass("hide")
    //       .addClass("show");
    //     $(this)
    //       .closest(".content-showcase__content-switcher--category--box")
    //       .find(".box__iconsmall")
    //       .removeClass("select-cat")
    //       .addClass("unselect-cat");
    //     $(`#link-${index}`)
    //       .removeClass("unselect-cat")
    //       .addClass("select-cat");
  
    //     var editSlideWrapper = $("#appendSlide");
    //     editSlideWrapper.children().remove();
  
    //     $(".cardLinkMobile").each(function(i, element) {
    //       $(element).attr("id", ``);
    //     });
  
    //     if (
    //       $(".content-showcase__showcase-illustration--animation").hasClass(
    //         "show"
    //       )
    //     ) {
    //       $(`.block-animation-${index + 1} .cardLinkMobile`).each(function(
    //         i,
    //         element
    //       ) {
    //         $(element).attr("id", `clm-${i}`);
    //       });
    //     }
    //     //console.log(index);
    //   } else {
    //     //event.preventDefault();
    //     //scrollToAnchor(anchor);
    //   }
    // });

  }



}

//Function scroll smoothy anchor tag
function scrollToAnchor(aid) {
  var aTag = $("a[name='" + aid + "']");
  $("html,body").animate({ scrollTop: aTag.offset().top }, "slow");
}


let parametersSwiper;
// //Function Swiper card Showcase Illustration
function swiperCard() {
  // let slideNumber = $(".content-showcase__content-switcher--category--box .swiper-container .swiper-slide").length;
  let slideIndex = 0;
  $(".content-showcase__showcase-illustration .content-showcase__showcase-illustration--animation").eq(slideIndex).removeClass("hide");

  if ($(".content-showcase__content-switcher--category--box .swiper-container").length) {
      parametersSwiper = new Swiper(
      ".content-showcase__content-switcher--category--box .swiper-container",
      {
        spaceBetween: 0,
        slidesPerView: 1,
        watchOverflow: true,
        noSwipingClass: "noswipe",
        centeredSlides: true,
        cssMode: true,

        //slidesOffsetAfter: 200,
        pagination: {
          el: ".swiper-pagination",
          dynamicBullets: false,
          clickable: true,
          // clickableClass: "box__iconsmall",
          renderBullet: function (index, className) {
            return '<div data-number="' + (index + 1) + '" class="' + className + ' swiper-slide box__iconsmall"><img class="img-fluid" src="../assets/Jeniot/images/motion-icon-small.png" alt="Immagine monossido di carbonio"></img><span class="slideNumber">' + (index + 1) + '</span></div>';
          },
        },
        navigation: {
          nextEl: ".sl-next",
          prevEl: ".sl-prev"
        },
        breakpoints: {
          767: {
            slidesPerView: 1,
            spaceBetween: 0
          }
        }
      }
    );
    // swiperSelectCard(siObject);
    // siObject.update();
    parametersSwiper.on('slideChangeTransitionEnd', function () {
      $(".content-showcase__showcase-illustration--cardMobile .swiper-external-wrapper").addClass("hide");
      slideIndex = parametersSwiper.activeIndex;
      $(".content-showcase__showcase-illustration .content-showcase__showcase-illustration--animation").addClass("hide");
      $(".content-showcase__showcase-illustration .content-showcase__showcase-illustration--animation").eq(slideIndex).removeClass("hide");
    });

  }
}

// //Function Swiper card
function swiperCardShowcase() {
  let swipersCardShowcase = [];

  if ($(".content-showcase__showcase-illustration--cardMobile .swiper-container").length) {
    
    $(".content-showcase__showcase-illustration--cardMobile .swiper-container").each(function(index, element){

      $(this).addClass('sw-cm'+index);

      const siObject = new Swiper(
        ".content-showcase__showcase-illustration--cardMobile .swiper-container",
        {
          spaceBetween: 12,
          slidesPerView: "auto",
          watchOverflow: true,
          noSwipingClass: "noswipe",
          slidesOffsetAfter: 200,
          breakpoints: {
            767: {
              slidesPerView: "auto",
              spaceBetween: 30
            }
          }
        }
      );
    });

    // swipersCardShowcase.append(siObject);

    // const siObject = new Swiper(
    //   ".content-showcase__showcase-illustration--cardMobile .swiper-container",
    //   {
    //     spaceBetween: 12,
    //     slidesPerView: "auto",
    //     watchOverflow: true,
    //     noSwipingClass: "noswipe",
    //     slidesOffsetAfter: 200,
    //     pagination: {
    //       el: ".swiper-pagination",
    //       dynamicBullets: true,
    //       clickable: true
    //     },
    //     navigation: {
    //       nextEl: ".sl-next",
    //       prevEl: ".sl-prev"
    //     },
    //     breakpoints: {
    //       767: {
    //         slidesPerView: "auto",
    //         spaceBetween: 30
    //       }
    //     }
    //   }
    // );
    // swiperSelectCard(siObject);
    // siObject.update();
  }
}



//Function Swiper card Showcase Illustration (param)
function swiperSliderParam() {
  // if ($("#slider-icons").length) {
  //   var siObject2 = new Swiper("#slider-icons", {
  //     spaceBetween: 10,
  //     slidesPerView: 5,
  //     freeMode: true,
  //     loopedSlides: 5, //looped slides should be the same
  //     watchSlidesVisibility: true,
  //     watchSlidesProgress: true,
  //     watchOverflow: true
  //   });
  // }
  // if ($("#slider-param").length) {
  //   var siObject3 = new Swiper("#slider-param", {
  //     spaceBetween: 12,
  //     loopedSlides: 5, //looped slides should be the same
  //     navigation: {
  //       nextEl: ".swiper-button-next",
  //       prevEl: ".swiper-button-prev"
  //     },
  //     thumbs: {
  //       swiper: siObject2
  //     }
  //     // breakpoints: {
  //     //   767: {
  //     //     slidesPerView: "auto",
  //     //     spaceBetween: 30
  //     //   }
  //     // }
  //   });
  //   siObject3.on("slideChange", function() {
  //     //console.log("slide changed");
  //   });
  // }
}

//Function Select cards on mobile (Showcase)
function swiperSelectCard(siObject, categoryShowcase) {


  if($("main").hasClass("scheda-prodotto")){
    let slideIndex = 0;

    $(".content-showcase__showcase-illustration--cardMobile .swiper-external-wrapper").addClass("hide");
    
    //var categoryShowcase = categoryShowcase;
    $(".cardLinkMobile").click(function(event) {
    
      slideIndex = parametersSwiper.activeIndex;
      // console.log("slideIndex --->",slideIndex );
  
      $(".content-showcase__showcase-illustration--cardMobile .swiper-external-wrapper").addClass("hide");
      $(".content-showcase__showcase-illustration--cardMobile .swiper-external-wrapper").eq(slideIndex).removeClass("hide");
  
      parametersSwiper.update();
  
      // var cardLink = event.target.getAttribute("id").replace("clm-", "");
  
      // var getIdCard = $(this)
      //   .closest(".content-showcase")
      //   .find(".select-cat")
      //   .attr("id")
      //   .replace("link-", "");
      // //console.log(getIdCard);
      // var editSlideWrapper = $("#appendSlide");
      // editSlideWrapper.children().remove();
      // //console.log(editSlideWrapper.length);
      // if (editSlideWrapper.children().length == 0) {
      //   editSlideWrapper.append($(`#cat-elem-${getIdCard}`).html());
      // }
      // //console.log(getIdCard);
  
      // //scrollToAnchor("cardMobile");
      // event.preventDefault();
      // siObject.slideTo(cardLink, false);
      // siObject.update();
    });
  }
}

//Focus on input search (Header menu)
function focusSearch() {
  $("#search").click(function() {
    $("html, body").removeClass("overflow-hidden");
    if ($("#search").hasClass("active")) {
      $(".s-magnifier").addClass("hidden");
      $(".s-close").removeClass("hidden");
    } else {
      $(".s-magnifier").removeClass("hidden");
      $(".s-close").addClass("hidden");
      document.getElementById("inputSearchDes").value = "";
    }
  });
}

function showResetForm() {
  $(".formlink-reset").addClass("hidden");
  $("#inputSearchMob").click(function() {
    if (screen.width < 990) {
      $(this)
        .closest("#formSearchMob")
        .find(".formlink-reset")
        .removeClass("hidden");
    }
  });
}

//Function autocomplete
function autoFormSearch() {
  var suffix = "";
  var res = screen.width;
  //console.log(res);
  if (res < 990) {
    suffix = "Mob";
  } else {
    suffix = "Des";
  }

  $(".formlink-reset > a").click(function() {
    var resetLink = document.getElementById("inputSearchMob");
    resetLink.value = "";
    resetLink.focus();
  });
  var listContent = {
    aree: [
      {
        titlecat: "Ricerche consigliate",
        area: "Afghanistan",
        urlImg: "../assets/Jeniot/images/user.svg"
      },
      {
        titlecat: "Ricerche consigliate",
        area: "Albania",
        urlImg: "../assets/Jeniot/images/user.svg"
      },
      {
        titlecat: "Ricerche consigliate",
        area: "Andorra",
        urlImg: "../assets/Jeniot/images/user.svg"
      },
      {
        titlecat: "Ricerche consigliate",
        area: "Andalusia",
        urlImg: "../assets/Jeniot/images/user.svg"
      }
    ]
  };

  var listLinks = {
    links: [
      {
        nameLink: "Assistenza",
        urlLink: "#"
      },
      {
        nameLink: "Reprehenderit in voluptate",
        urlLink: "#"
      }
    ]
  };

  var aree = listContent.aree;
  var links = listLinks.links;

  function autocomplete(inp, aree) {
    /*the autocomplete function takes two arguments,
    the text field element and an array of possible autocompleted values:*/

    var currentFocus;
    /*execute a function when someone writes in the text field:*/
    inp.addEventListener("input", function(e) {
      var a,
        b,
        i,
        val = this.value;
      /*close any already open lists of autocompleted values*/
      var checkMatch = false;
      closeAllLists();
      if (!val) {
        return false;
      }
      currentFocus = -1;
      /*create a DIV element that will contain the items (values):*/
      a = document.createElement("DIV");
      a.setAttribute("id", this.id + "autocomplete-list");
      a.setAttribute("class", "autocomplete-items");
      /*append the DIV element as a child of the autocomplete container:*/
      //this.parentNode.appendChild(a);
      var menuSearchContainerId = "menuSearchContainer" + suffix;
      var menuSearchContainer = document.getElementById(menuSearchContainerId);
      menuSearchContainer.setAttribute("id", menuSearchContainerId);
      document.getElementById(menuSearchContainerId).appendChild(a);

      /*for each item in the array...*/
      var titoloIns = "";
      var textNoResult = "";

      for (i = 0; i < aree.length; i++) {
        var listContentArea = aree[i];
        var titolo = listContentArea.titlecat;
        var area = listContentArea.area;
        var url = listContentArea.urlImg;

        if (titoloIns != titolo) {
          if (titoloIns != "") {
            var sepCatSearch = document.createElement("DIV");
            sepCatSearch.classList.add("sepcatsearch");
            document
              .getElementById(`inputSearch${suffix}autocomplete-list`)
              .appendChild(sepCatSearch);
          }

          var titleCatSearch = document.createElement("DIV");
          var titoloReplace = titolo;
          titoloReplace = titoloReplace.replace(" ", "-");
          titoloReplace = titoloReplace.toLowerCase();
          titleCatSearch.setAttribute("id", titoloReplace);
          titleCatSearch.classList.add("labelsearch");
          titleCatSearch.innerHTML = titolo;
          document
            .getElementById(`inputSearch${suffix}autocomplete-list`)
            .appendChild(titleCatSearch);
          titoloIns = titolo;
        }

        /*check if the item starts with the same letters as the text field value:*/
        if (area.substr(0, val.length).toUpperCase() === val.toUpperCase()) {
          checkMatch = true;
          /*create a DIV element for each matching element:*/
          b = document.createElement("DIV");
          /*make the matching letters bold:*/
          b.innerHTML = "<img src=" + url + " alt='immagine'>";
          b.innerHTML += "<strong>" + area.substr(0, val.length) + "</strong>";
          b.innerHTML += area.substr(val.length);
          /*insert a input field that will hold the current array item's value:*/
          b.innerHTML += "<input type='hidden' value='" + area + "'>";
          /*execute a function when someone clicks on the item value (DIV element):*/
          b.addEventListener("click", function(e) {
            /*insert the value for the autocomplete text field:*/
            inp.value = this.getElementsByTagName("input")[0].value;
            /*close the list of autocompleted values,
                (or any other open lists of autocompleted values:*/
            closeAllLists();
          });
          a.appendChild(b);
        }
      }
      if (!checkMatch) {
        var ricerche = document.getElementById("ricerche-consigliate");
        if (ricerche) ricerche.style.display = "none";
      }
      // Useful links: title
      var usefulLinksTitle = document.createElement("DIV");
      usefulLinksTitle.classList.add("labelsearch");
      if (links.length>0) usefulLinksTitle.innerHTML = "Link utili";
      document
        .getElementById(`inputSearch${suffix}autocomplete-list`)
        .appendChild(usefulLinksTitle);

      for (i = 0; i < links.length; i++) {
        var listContentLink = links[i];
        var nameLink = listContentLink.nameLink;
        var urllink = listContentLink.urlLink;

        // Useful links: links
        var usefulLinksUrl = document.createElement("DIV");
        usefulLinksUrl.classList.add("useful-links");
        usefulLinksUrl.innerHTML =
          "<a href='" +
          urllink +
          "' title=''><img src='../assets/Jeniot/images/user.svg' alt='immagine'>" +
          nameLink;
        usefulLinksUrl.innerHTML += "</a>";
        document
          .getElementById(`inputSearch${suffix}autocomplete-list`)
          .appendChild(usefulLinksUrl);
      }

      // More results
      var moreResult = document.createElement("DIV");
      moreResult.classList.add("moreresult");
      if (links.length>0) moreResult.innerHTML =
        "<a class='text-underline semibold-text violet-text link' href='#'>Mostra tutti i risultati</a>";
      document
        .getElementById(`inputSearch${suffix}autocomplete-list`)
        .appendChild(moreResult);
    });
    /*execute a function presses a key on the keyboard:*/
    inp.addEventListener("keydown", function(e) {
      document.getElementById("menuSearchContainer" + suffix).style.display =
        "block";
      var x = document.getElementById(this.id + "autocomplete-list");
      if (x) x = x.getElementsByTagName("div");
      if (e.keyCode == 40) {
        /*If the arrow DOWN key is pressed,
          increase the currentFocus variable:*/
        currentFocus++;
        /*and and make the current item more visible:*/
        addActive(x);
      } else if (e.keyCode == 38) {
        //up
        /*If the arrow UP key is pressed,
          decrease the currentFocus variable:*/
        currentFocus--;
        /*and and make the current item more visible:*/
        addActive(x);
      } else if (e.keyCode == 13) {
        /*If the ENTER key is pressed, prevent the form from being submitted,*/
        e.preventDefault();
        if (currentFocus > -1) {
          /*and simulate a click on the "active" item:*/
          if (x) x[currentFocus].click();
        }
      }
    });
    function addActive(x) {
      /*a function to classify an item as "active":*/
      if (!x) return false;
      /*start by removing the "active" class on all items:*/
      removeActive(x);
      if (currentFocus >= x.length) currentFocus = 0;
      if (currentFocus < 0) currentFocus = x.length - 1;
      /*add class "autocomplete-active":*/
      x[currentFocus].classList.add("autocomplete-active");
    }
    function removeActive(x) {
      /*a function to remove the "active" class from all autocomplete items:*/
      for (var i = 0; i < x.length; i++) {
        x[i].classList.remove("autocomplete-active");
      }
    }
    function closeAllLists(elmnt) {
      /*close all autocomplete lists in the document,
      except the one passed as an argument:*/
      if($(".autocomplete-items")){
        var x = document.getElementsByClassName("autocomplete-items");
        for (var i = 0; i < x.length; i++) {
          if (elmnt != x[i] && elmnt != inp) {
            x[i].parentNode.removeChild(x[i]);
          } else {
          }
        }
      }
    }
    /*execute a function when someone clicks in the document:*/
    document.addEventListener("click", function(e) {
      closeAllLists(e.target);
    });
  }

  autocomplete(document.getElementById("inputSearch" + suffix), aree);
}

// Function overflow body mobile
function overflowBodyMobile() {
  $(".navbar-toggler").click(function() {
    if ($(this).hasClass("active")) {
      $(this)
        .closest("html")
        .addClass("overflow-hidden");
    } else {
      $(this)
        .closest("html")
        .removeClass("overflow-hidden");
    }
  });

  $(".button-modal-form button").click(function() {
    $(this)
      .closest("html")
      .addClass("overflow-hidden");
  });

  $(".megamenu-mobile--title .icon-close").click(function() {
    $(this)
      .closest("html")
      .removeClass("overflow-hidden");
  });
  $(".modal").focusout(function() {
    $(this)
      .closest("html")
      .removeClass("overflow-hidden");
  });
}

//DOCUMENT READY INIT SCRIPT
$(document).ready(function() {
  jeniot.init();
});

//Function onResize
$(window).resize(function() {
  truncateText(
    ".suggested-articles__content--description.ellipsis",
    ".suggested-articles__content--description.ellipsis > a > h3"
  );
  swiperQuotesCarousel();
  swiperEcommerceCardGrid2();
  swiperEcommerceCardGrid4();
  swiperIndicatorCarousel();
  swiperSliderParam();
  // setHeight();
  equalHeight(".card-buttons__card .sameHeight");
  equalHeight(".ecommerce-card__boxcard--title > .sameHeight2");
  equalHeight(".ecommerce-card__boxcard--title > .sameHeight3");
  equalHeight(".ecommerce-card__boxcard--desc > .sameHeight4");
  equalHeight(".grid-section__block-title > .sameHeight5");
  equalHeight(".grid-section__block-desc > .sameHeight6");
  autoFormSearch();
  overflowBodyMobile();
});

//Function onScroll

// When the user scrolls the page, execute myFunction
//Scroll sticky (partial)
// $(document).scroll(function(e) {
//   if (window.pageYOffset !== 0) {
//     stickyContent();
//   }
// });

// Get the content element
//var stickyBlock = document.getElementById("contentSticky");

// Add the sticky class to the content element when you reach its scroll position. Remove "sticky" when you leave the scroll position
// function stickyContent() {

//   if (window.pageYOffset >= $("#contentItem").offset().top) {
//     stickyBlock.classList.add("sticky");
//   } else {
//     stickyBlock.classList.remove("sticky");
//   }
// }
