// document.addEventListener("DOMContentLoaded", () => {
//   //init scrollmagic
//   let controller = new ScrollMagic.Controller();

//   let timelineIntro = new TimelineMax();

//   function introListener(){
//     var height = $(window).scrollTop();
//     if(height  === 0) {
//       timelineIntro.play()
//     }
//     else{
//       timelineIntro.progress(1)
//     }
//   }
//   $(window).scroll(function() {
//     introListener();
//   });
//   introListener();

//   let timeline = new TimelineMax();
//   let timeline2 = new TimelineMax();

//   var mql = window.matchMedia("(min-width: 768px)");

//   function createTimeline(e) {
//     if (e.matches) {
//       //-console.log("--------ANIMAZIONE DESKTOP--------")

//       //-------------------------------------------------------------------------------------------------------------------------------
//       //DESKTOP TIMELINE
//       //-------------------------------------------------------------------------------------------------------------------------------

//       timelineIntro
//       .from(
//         ".text-block-A",
//         2,
//         {
//           y: 0,
//           x: -700,
//           ease: Linear.easeNone,
//           autoAlpha: 0
//         }
//       )
//       .from(
//         ".img-arya-A",
//         3,
//         {
//           y: -300,
//           x: -700,
//           ease: Linear.easeNone,
//           autoAlpha: 0
//         },
//         "-=3"
//       )
//       .from(
//         ".img-arya-A-blurred",
//         3,
//         {
//           y: -300,
//           x: -700,
//           ease: Linear.easeNone,
//           autoAlpha: 0
//         },
//         "-=3"
//       )
//       .from(
//         ".img-arya-B",
//         3,
//         {
//           y: -300,
//           x: -700,
//           ease: Linear.easeNone,
//           autoAlpha: 0
//         },
//         "-=3"
//       )
//       .from(
//         ".img-arya-B-blurred",
//         3,
//         {
//           y: -300,
//           x: -700,
//           ease: Linear.easeNone,
//           autoAlpha: 0
//         },
//         "-=3"
//       )
//       .add("intro")
//       // timeline
//       //   .clear()
//       //   .from(
//       //     ".img-arya-A",
//       //     1,
//       //     {
//       //       y: -300,
//       //       x: -700,
//       //       ease: Linear.easeNone,
//       //       autoAlpha: 0
//       //     },
//       //     "-=1"
//       //   )
//       //   .from(
//       //     ".img-arya-A-blurred",
//       //     1,
//       //     {
//       //       y: -300,
//       //       x: -700,
//       //       ease: Linear.easeNone,
//       //       autoAlpha: 0
//       //     },
//       //     "-=1"
//       //   )
//       //   .from(
//       //     ".img-arya-B",
//       //     1,
//       //     {
//       //       y: -300,
//       //       x: -700,
//       //       ease: Linear.easeNone,
//       //       autoAlpha: 0
//       //     },
//       //     "-=1"
//       //   )
//       //   .from(
//       //     ".img-arya-B-blurred",
//       //     1,
//       //     {
//       //       y: -300,
//       //       x: -700,
//       //       ease: Linear.easeNone,
//       //       autoAlpha: 0
//       //     },
//       //     "-=1"
//       //   )
//       //   .add("intro")
//         //----------------------------------------------------------------
//         // From initial position to 50% of first section
//         //----------------------------------------------------------------
//         timeline
//         .fromTo(
//           ".img-arya-A",
//           1,
//           {
//             y: 0,
//             x: 0
//           },
//           {
//             y: "59%",
//             x: "66.5%",
//             ease: Linear.easeNone,
//             autoAlpha: 0,
//             scale: 0.7
//           },
//           "intro"
//         )
//         .fromTo(
//           ".img-arya-A-blurred",
//           1,
//           {
//             y: 0,
//             x: 0
//           },
//           {
//             y: "59%",
//             x: "66.5%",
//             ease: Linear.easeNone,
//             autoAlpha: 0.5,
//             scale: 0.7
//           },
//           "intro"
//         )
//         .fromTo(
//           ".img-arya-B",
//           1,
//           {
//             y: 0,
//             x: 0
//           },
//           {
//             y: "59%",
//             x: "66.5%",
//             ease: Linear.easeNone,
//             autoAlpha: 0,
//             scale: 0.7
//           },
//           "intro"
//         )
//         .fromTo(
//           ".img-arya-B-blurred",
//           1,
//           {
//             y: 0,
//             x: 0
//           },
//           {
//             y: "59%",
//             x: "66.5%",
//             ease: Linear.easeNone,
//             autoAlpha: 0.5,
//             scale: 0.7
//           },
//           "intro"
//         )
//         .add("section01-50")
//         //----------------------------------------------------------------
//         // From 50% of first section to 100% of first section
//         //----------------------------------------------------------------
//         .fromTo(
//           ".img-arya-A",
//           1,
//           {
//             y: 0,
//             x: 0
//           },
//           {
//             y: "118%",
//             x: "133%",
//             ease: Linear.easeNone,
//             autoAlpha: 0,
//             scale: 0.9
//           },
//           "section01-50"
//         )
//         .fromTo(
//           ".img-arya-A-blurred",
//           1,
//           {
//             y: 0,
//             x: 0
//           },
//           {
//             y: "118%",
//             x: "133%",
//             ease: Linear.easeNone,
//             autoAlpha: 0,
//             scale: 0.9
//           },
//           "section01-50"
//         )
//         .fromTo(
//           ".img-arya-B",
//           1,
//           {
//             y: 0,
//             x: 0
//           },
//           {
//             y: "118%",
//             x: "133%",
//             ease: Linear.easeNone,
//             autoAlpha: 1,
//             scale: 0.9
//           },
//           "section01-50"
//         )
//         .fromTo(
//           ".img-arya-B-blurred",
//           1,
//           {
//             y: 0,
//             x: 0
//           },
//           {
//             y: "118%",
//             x: "133%",
//             ease: Linear.easeNone,
//             autoAlpha: 0,
//             scale: 0.9
//           },
//           "section01-50"
//         )
//         .add("section01-100")
//         //----------------------------------------------------------------
//         // From 0% of second section to 50% of second section
//         //----------------------------------------------------------------
//         .fromTo(
//           ".img-arya-A",
//           1,
//           {
//             y: 0,
//             x: 0
//           },
//           {
//             y: "177%",
//             x: "66.5%",
//             ease: Linear.easeNone,
//             autoAlpha: 0,
//             scale: 0.7
//           },
//           "section01-100"
//         )
//         .fromTo(
//           ".img-arya-A-blurred",
//           1,
//           {
//             y: 0,
//             x: 0
//           },
//           {
//             y: "177%",
//             x: "66.5%",
//             ease: Linear.easeNone,
//             autoAlpha: 0.5,
//             scale: 0.7
//           },
//           "section01-100"
//         )
//         .fromTo(
//           ".img-arya-B",
//           1,
//           {
//             y: 0,
//             x: 0
//           },
//           {
//             y: "177%",
//             x: "66.5%",
//             ease: Linear.easeNone,
//             autoAlpha: 0,
//             scale: 0.7
//           },
//           "section01-100"
//         )
//         .fromTo(
//           ".img-arya-B-blurred",
//           1,
//           {
//             y: 0,
//             x: 0
//           },
//           {
//             y: "177%",
//             x: "66.5%",
//             ease: Linear.easeNone,
//             autoAlpha: 0.5,
//             scale: 0.7
//           },
//           "section01-100"
//         )
//         .add("section02-50")
//         //----------------------------------------------------------------
//         // From 50% of second section to 100% of second section
//         //----------------------------------------------------------------
//         .to("#particle-co2", 1, {
//           autoAlpha: 1
//         })
//         .fromTo(
//           ".img-arya-A",
//           1,
//           {
//             y: 0,
//             x: 0
//           },
//           {
//             y: "290%",
//             x: "17%",
//             ease: Linear.easeNone,
//             autoAlpha: 0,
//             scale: 0.9
//           },
//           "section02-50"
//         )
//         .fromTo(
//           ".img-arya-A-blurred",
//           1,
//           {
//             y: 0,
//             x: 0
//           },
//           {
//             y: "290%",
//             x: "17%",
//             ease: Linear.easeNone,
//             autoAlpha: 0,
//             scale: 0.9
//           },
//           "section02-50"
//         )
//         .fromTo(
//           ".img-arya-B",
//           1,
//           {
//             y: 0,
//             x: 0
//           },
//           {
//             y: "290%",
//             x: "17%",
//             ease: Linear.easeNone,
//             autoAlpha: 1,
//             scale: 0.9
//           },
//           "section02-50"
//         )
//         .fromTo(
//           ".img-arya-B-blurred",
//           1,
//           {
//             y: 0,
//             x: 0
//           },
//           {
//             y: "290%",
//             x: "17%",
//             ease: Linear.easeNone,
//             autoAlpha: 0,
//             scale: 0.9
//           },
//           "section02-50"
//         )
//         .add("section02-100")
//         //----------------------------------------------------------------
//         // From 0% of third section to 50% of third section
//         //----------------------------------------------------------------
//         .fromTo(
//           ".img-arya-A",
//           1,
//           {
//             y: 0,
//             x: 0
//           },
//           {
//             y: "300%",
//             x: "37%",
//             ease: Linear.easeNone,
//             autoAlpha: 0,
//             scale: 0.7
//           },
//           "section02-100"
//         )
//         .fromTo(
//           ".img-arya-A-blurred",
//           1,
//           {
//             y: 0,
//             x: 0
//           },
//           {
//             y: "300%",
//             x: "37%",
//             ease: Linear.easeNone,
//             autoAlpha: 0.5,
//             scale: 0.7
//           },
//           "section02-100"
//         )
//         .fromTo(
//           ".img-arya-B",
//           1,
//           {
//             y: 0,
//             x: 0
//           },
//           {
//             y: "300%",
//             x: "37%",
//             ease: Linear.easeNone,
//             autoAlpha: 0,
//             scale: 0.7
//           },
//           "section02-100"
//         )
//         .fromTo(
//           ".img-arya-B-blurred",
//           1,
//           {
//             y: 0,
//             x: 0
//           },
//           {
//             y: "300%",
//             x: "37%",
//             ease: Linear.easeNone,
//             autoAlpha: 0.5,
//             scale: 0.7
//           },
//           "section02-100"
//         )
//         .add("section03-50")

//         //----------------------------------------------------------------
//         // From 50% of third section to 100% of third section
//         //----------------------------------------------------------------

//         .fromTo(
//           ".img-arya-A",
//           1,
//           {
//             y: 0,
//             x: 0
//           },
//           {
//             y: "350%",
//             x: "75%",
//             ease: Linear.easeNone,
//             autoAlpha: 0.5,
//             scale: 0.5
//           },
//           "section03-50"
//         )
//         .fromTo(
//           ".img-arya-A-blurred",
//           1,
//           {
//             y: 0,
//             x: 0
//           },
//           {
//             y: "350%",
//             x: "75%",
//             ease: Linear.easeNone,
//             autoAlpha: 0,
//             scale: 0.5
//           },
//           "section03-50"
//         )
//         .fromTo(
//           ".img-arya-B",
//           1,
//           {
//             y: 0,
//             x: 0
//           },
//           {
//             y: "350%",
//             x: "75%",
//             ease: Linear.easeNone,
//             autoAlpha: 0,
//             scale: 0.5
//           },
//           "section03-50"
//         )
//         .fromTo(
//           ".img-arya-B-blurred",
//           1,
//           {
//             y: 0,
//             x: 0
//           },
//           {
//             y: "350%",
//             x: "75%",
//             ease: Linear.easeNone,
//             autoAlpha: 0,
//             scale: 0.5
//           },
//           "section03-50"
//         )
//         .add("section03-100");


//       //-------------------------------------------------------------------------------------------------------------------------------
//       //DESKTOP TIMELINE FOR SCENE 2
//       //-------------------------------------------------------------------------------------------------------------------------------

//       timeline2
//         .to(".img-arya-A", 1, {scale: 0.8, autoAlpha: 1 })
//         .to(".product .img-arya", 1, {
//           css: { scaleX: 2.5, scaleY: 2.5, opacity: 1 }
//         }, "-=1")
//         .to(".images-wrapper.top", 1, {
//           height: 0
//         })
//         .to(".product .img-arya .blur", 1, { css: { opacity: 1 } }, "-=1");
//     } else {
//       //-console.log("--------ANIMAZIONE MOBILE--------")

//       //-------------------------------------------------------------------------------------------------------------------------------
//       //MOBILE TIMELINE
//       //-------------------------------------------------------------------------------------------------------------------------------

//       timeline
//         .clear()
//         .add("intro")
//         //----------------------------------------------------------------
//         // From initial position to 50% of first section
//         //----------------------------------------------------------------
//         .fromTo(
//           ".img-arya-A",
//           1,
//           {
//             y: 0,
//             x: 0
//           },
//           {
//             y: "78%",
//             x: 0,
//             ease: Linear.easeNone,
//             autoAlpha: 0,
//             scale: 0.7
//           },
//           "intro"
//         )
//         .fromTo(
//           ".img-arya-A-blurred",
//           1,
//           {
//             y: 0,
//             x: 0,
//           },
//           {
//             y: "78%",
//             x: 0,
//             ease: Linear.easeNone,
//             autoAlpha: 0.5,
//             scale: 0.7
//           },
//           "intro"
//         )
//         .fromTo(
//           ".img-arya-B",
//           1,
//           {
//             y: 0,
//             x: 0,
//           },
//           {
//             y: "78%",
//             x: 0,
//             ease: Linear.easeNone,
//             autoAlpha: 0,
//             scale: 0.7
//           },
//           "intro"
//         )
//         .fromTo(
//           ".img-arya-B-blurred",
//           1,
//           {
//             y: 0,
//             x: 0
//           },
//           {
//             y: "78%",
//             x: 0,
//             ease: Linear.easeNone,
//             autoAlpha: 0.5,
//             scale: 0.7
//           },
//           "intro"
//         )
//         .add("section01-50")
//         //----------------------------------------------------------------
//         // From 50% of first section to 100% of first section
//         //----------------------------------------------------------------
//         .fromTo(
//           ".img-arya-A",
//           1,
//           {
//             y: 0,
//             x: 0
//           },
//           {
//             y: "155%",
//             x: 0,
//             ease: Linear.easeNone,
//             autoAlpha: 0,
//             scale: 1.2
//           },
//           "section01-50"
//         )
//         .fromTo(
//           ".img-arya-A-blurred",
//           1,
//           {
//             y: 0,
//             x: 0
//           },
//           {
//             y: "155%",
//             x: 0,
//             ease: Linear.easeNone,
//             autoAlpha: 0,
//             scale: 1.2
//           },
//           "section01-50"
//         )
//         .fromTo(
//           ".img-arya-B",
//           1,
//           {
//             y: 0,
//             x: 0
//           },
//           {
//             y: "155%",
//             x: 0,
//             ease: Linear.easeNone,
//             autoAlpha: 1,
//             scale: 1.2
//           },
//           "section01-50"
//         )
//         .fromTo(
//           ".img-arya-B-blurred",
//           1,
//           {
//             y: 0,
//             x: 0
//           },
//           {
//             y: "155%",
//             x: 0,
//             ease: Linear.easeNone,
//             autoAlpha: 0,
//             scale: 1.2
//           },
//           "section01-50"
//         )
//         .add("section01-100")
//         //----------------------------------------------------------------
//         // From 0% of second section to 50% of second section
//         //----------------------------------------------------------------
//         .fromTo(
//           ".img-arya-A",
//           1,
//           {
//             y: 0,
//             x: 0
//           },
//           {
//             y: "217%",
//             x: 0,
//             ease: Linear.easeNone,
//             autoAlpha: 0,
//             scale: 0.7
//           },
//           "section01-100"
//         )
//         .fromTo(
//           ".img-arya-A-blurred",
//           1,
//           {
//             y: 0,
//             x: 0
//           },
//           {
//             y: "217%",
//             x: 0,
//             ease: Linear.easeNone,
//             autoAlpha: 0.5,
//             scale: 0.7
//           },
//           "section01-100"
//         )
//         .fromTo(
//           ".img-arya-B",
//           1,
//           {
//             y: 0,
//             x: 0
//           },
//           {
//             y: "217%",
//             x: 0,
//             ease: Linear.easeNone,
//             autoAlpha: 0,
//             scale: 0.7
//           },
//           "section01-100"
//         )
//         .fromTo(
//           ".img-arya-B-blurred",
//           1,
//           {
//             y: 0,
//             x: 0
//           },
//           {
//             y: "217%",
//             x: 0,
//             ease: Linear.easeNone,
//             autoAlpha: 0.5,
//             scale: 0.7
//           },
//           "section01-100"
//         )
//         .add("section02-50")
//         //----------------------------------------------------------------
//         // From 50% of second section to 100% of second section
//         //----------------------------------------------------------------
//         .to("#particle-co2", 1, {
//           autoAlpha: 1
//         })
//         .fromTo(
//           ".img-arya-A",
//           1,
//           {
//             y: 0,
//             x: 0
//           },
//           {
//             y: "310%",
//             x: 0,
//             ease: Linear.easeNone,
//             autoAlpha: 0,
//             scale: 1.2
//           },
//           "section02-50"
//         )
//         .fromTo(
//           ".img-arya-A-blurred",
//           1,
//           {
//             y: 0,
//             x: 0
//           },
//           {
//             y: "310%",
//             x: 0,
//             ease: Linear.easeNone,
//             autoAlpha: 0,
//             scale: 1.2
//           },
//           "section02-50"
//         )
//         .fromTo(
//           ".img-arya-B",
//           1,
//           {
//             y: 0,
//             x: 0
//           },
//           {
//             y: "310%",
//             x: 0,
//             ease: Linear.easeNone,
//             autoAlpha: 1,
//             scale: 1.2
//           },
//           "section02-50"
//         )
//         .fromTo(
//           ".img-arya-B-blurred",
//           1,
//           {
//             y: 0,
//             x: 0
//           },
//           {
//             y: "310%",
//             x: 0,
//             ease: Linear.easeNone,
//             autoAlpha: 0,
//             scale: 1.2
//           },
//           "section02-50"
//         )
//         .add("section02-100")
//         //----------------------------------------------------------------
//         // From 0% of third section to 50% of third section
//         //----------------------------------------------------------------
//         .fromTo(
//           ".img-arya-A",
//           1,
//           {
//             y: 0,
//             x: 0
//           },
//           {
//             y: "345%",
//             x: 0,
//             ease: Linear.easeNone,
//             autoAlpha: 0,
//             scale: 0.7
//           },
//           "section02-100"
//         )
//         .fromTo(
//           ".img-arya-A-blurred",
//           1,
//           {
//             y: 0,
//             x: 0
//           },
//           {
//             y: "345%",
//             x: 0,
//             ease: Linear.easeNone,
//             autoAlpha: 0.5,
//             scale: 0.7
//           },
//           "section02-100"
//         )
//         .fromTo(
//           ".img-arya-B",
//           1,
//           {
//             y: 0,
//             x: 0
//           },
//           {
//             y: "345%",
//             x: 0,
//             ease: Linear.easeNone,
//             autoAlpha: 0,
//             scale: 0.7
//           },
//           "section02-100"
//         )
//         .fromTo(
//           ".img-arya-B-blurred",
//           1,
//           {
//             y: 0,
//             x: 0
//           },
//           {
//             y: "345%",
//             x: 0,
//             ease: Linear.easeNone,
//             autoAlpha: 0.5,
//             scale: 0.7
//           },
//           "section02-100"
//         )
//         .add("section03-50")

//         //----------------------------------------------------------------
//         // From 50% of third section to 100% of third section
//         //----------------------------------------------------------------

//         .fromTo(
//           ".img-arya-A",
//           1,
//           {
//             y: 0,
//             x: 0
//           },
//           {
//             y: "420%",
//             x: 0,
//             ease: Linear.easeNone,
//             autoAlpha: 0.5,
//             scale: 0.5
//           },
//           "section03-50"
//         )
//         .fromTo(
//           ".img-arya-A-blurred",
//           1,
//           {
//             y: 0,
//             x: 0
//           },
//           {
//             y: "420%",
//             x: 0,
//             ease: Linear.easeNone,
//             autoAlpha: 0,
//             scale: 0.5
//           },
//           "section03-50"
//         )
//         .fromTo(
//           ".img-arya-B",
//           1,
//           {
//             y: 0,
//             x: 0
//           },
//           {
//             y: "420%",
//             x: 0,
//             ease: Linear.easeNone,
//             autoAlpha: 0,
//             scale: 0.5
//           },
//           "section03-50"
//         )
//         .fromTo(
//           ".img-arya-B-blurred",
//           1,
//           {
//             y: 0,
//             x: 0
//           },
//           {
//             y: "420%",
//             x: 0,
//             ease: Linear.easeNone,
//             autoAlpha: 0,
//             scale: 0.5
//           },
//           "section03-50"
//         )
//         .add("section03-100");

//       //-------------------------------------------------------------------------------------------------------------------------------
//       //MOBILE TIMELINE FOR SCENE 2
//       //-------------------------------------------------------------------------------------------------------------------------------

//       //timeline2
//         // .clear()
//         // .to(".img-arya-A", 1, {
//         //   css: { scaleX: 1.5, scaleY: 1.5, opacity: 1.5 }
//         // })
//         // .to(".images-wrapper.top", 3, {
//         //   height: 0
//         // })
//         // .to(".product .img-arya .blur", 3, { css: { opacity: 1 } }, "-=3");

//       timeline2
//         .to(".img-arya-A", 1, {scale: 0.8, autoAlpha: 1 })
//         .to(".product .img-arya", 1, {
//           css: { scaleX: 1.5, scaleY: 1.5, opacity: 1 }
//         }, "-=1")
//         .to(".images-wrapper.top", 1, {
//           height: 0
//         })
//         .to(".product .img-arya .blur", 1, { css: { opacity: 1 } }, "-=1");
//     }
//   }

//   //building scrollmagic scene

//   let scene = new ScrollMagic.Scene({
//     triggerElement: ".scheda-prodotto",
//     triggerHook: 0,
//     offset: "300",
//     duration: "2600"
//     //height: 3621px;
//   });

//   let sceneMobile = new ScrollMagic.Scene({
//     triggerElement: ".first-section",
//     triggerHook: 0.05,
//     offset: "90",
//     duration: "3050"
//     //height: 3778px;
//   });

//   let scene2 = new ScrollMagic.Scene({
//     triggerElement: ".smart-light-section",
//     duration: "20%",
//     triggerHook: 0.15,
//     offset: "0"
//   });

//   let scene2Mobile = new ScrollMagic.Scene({
//     triggerElement: ".smart-light-section",
//     duration: "100",
//     triggerHook: 0.35,
//     offset: "80"
//   });

//   function chooseTween() {
//     if (window.matchMedia("(min-width: 990px)").matches) {
//       //-console.log("chooseTween > 990")
//       timeline.kill();
//       timeline2.kill();
//       //scene.addIndicators()
//       scene.setTween(timeline);
//       scene.addTo(controller);

//       //scene2.addIndicators()
//       scene2.setTween(timeline2);
//       //scene2.setPin('.smart-light-section')
//       scene2.addTo(controller);
//     } else {
//       //-console.log("chooseTween < 990")
//       timeline.kill();
//       timeline2.kill();
//       //sceneMobile.addIndicators({colorTrigger: "blue", colorStart: "blue", colorEnd: "blue", indent: 10, name: "scene01-MOB"})
//       sceneMobile.setTween(timeline);
//       sceneMobile.addTo(controller);

//       //scene2Mobile.addIndicators({colorTrigger: "orange", colorStart: "orange", colorEnd: "orange", indent: 50, name: "scene02-MOB"})
//       scene2Mobile.setTween(timeline2);
//       //scene2Mobile.setPin(".smart-light-section");
//       scene2Mobile.addTo(controller);
//     }
//   }

//   const pageStandardWidth = 1440;

//   function setZoomOfMainAnimation() {
//     if (window.matchMedia("(min-width: 768px)").matches) {
//       var ow = $(window).width();
//       var zc = ow / pageStandardWidth;
//       $(".animation-wrapper").css("zoom", zc);
//     }
//   }

//   mql.addListener(createTimeline);

//   createTimeline(mql);

//   setZoomOfMainAnimation();
//   chooseTween();

//   window.onresize = function(event) {
//     setTimeout(function() {
//       chooseTween();
//       setZoomOfMainAnimation();
//     }, 50);
//   };

//   if($("#particles-js")){
//     particlesJS("particles-js", {
//       particles: {
//         number: {
//           value: 10,
//           density: {
//             enable: true,
//             value_area: 600
//           }
//         },
//         color: {
//           value: "#85cee4"
//         },
//         shape: {
//           type: "circle",
//           stroke: {
//             width: 0,
//             color: "#000"
//           },
//           polygon: {
//             nb_sides: 3
//           }
//         },
//         opacity: {
//           value: 0.4890406785639845,
//           random: true,
//           anim: {
//             enable: true,
//             speed: 0.08120772123013452,
//             opacity_min: 0.09744926547616141,
//             sync: false
//           }
//         },
//         size: {
//           value: 32.06824121731046,
//           random: true,
//           anim: {
//             enable: true,
//             speed: 12.181158184520175,
//             size_min: 5.684540486109416,
//             sync: false
//           }
//         },
//         line_linked: {
//           enable: false,
//           distance: 689.4671861721748,
//           color: "#ffffff",
//           opacity: 0.70550130678083,
//           width: 1.763753266952075
//         },
//         move: {
//           enable: true,
//           speed: 8,
//           direction: "none",
//           random: false,
//           straight: false,
//           out_mode: "out",
//           bounce: false,
//           attract: {
//             enable: false,
//             rotateX: 600,
//             rotateY: 1200
//           }
//         }
//       },
//       retina_detect: true
//     });
//   }

//   if($("#particle-co2")){
//     particlesJS("particle-co2", {
//       particles: {
//         number: {
//           value: 5,
//           density: {
//             enable: true,
//             value_area: 500
//           }
//         },
//         color: {
//           value: "#1b1e34"
//         },
//         shape: {
//           type: "image",
//           image: {
//             src: "../assets/Jeniot/images/ico-co2.png",
//             width: 42,
//             height: 22
//           }
//         },
//         opacity: {
//           value: 0.4890406785639845,
//           random: true,
//           anim: {
//             enable: true,
//             speed: 0.08120772123013452,
//             opacity_min: 0.09744926547616141,
//             sync: false
//           }
//         },
//         size: {
//           value: 32.06824121731046,
//           random: true,
//           anim: {
//             enable: true,
//             speed: 12.181158184520175,
//             size_min: 5.684540486109416,
//             sync: false
//           }
//         },
//         move: {
//           enable: true,
//           speed: 8,
//           direction: "right",
//           random: true,
//           straight: false,
//           out_mode: "out",
//           bounce: false,
//           attract: {
//             enable: false,
//             rotateX: 600,
//             rotateY: 1200
//           }
//         }
//       },
//       retina_detect: true
//     });
//   }


// });
