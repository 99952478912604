// $(window).on('load', function () {

//   $('.preloader').fadeOut('slow');

//   const video = document.getElementById('video');
//   const long = document.getElementById('long');
//   const ancc = document.getElementById('light');

//   let scrollpos = 0;
//   let lastpos;
//   const controller = new ScrollMagic.Controller();
//   const scene = new ScrollMagic.Scene({
//     triggerElement: long,
//     triggerHook: "0.1",
//     offset: "0",
//   });


//   if(video) video.pause();

//   const startScrollAnimation = () => {
//     let duration = long.clientHeight - ancc.clientHeight;

//     // console.log("long.clientHeight-->", long.clientHeight)
//     // console.log("ancc.clientHeight-->", ancc.clientHeight)
//     // console.log("duration-->", duration)

//     scene
//       .addTo(controller)
//       .duration(duration)
//       //.addIndicators()
//       .setPin(".video-wrapper")
//       .on("progress", (e) => {
//         scrollpos = e.progress;
//       });

//     setInterval(() => {
//       if (lastpos === scrollpos) return;
//       requestAnimationFrame(() => {
//         video.currentTime = video.duration * scrollpos;
//         video.pause();
//         lastpos = scrollpos;
//         //console.log(video.currentTime, scrollpos);
//       });
//     }, 50);
//   };

//     //- let timeline = new TimelineMax();
//     let timeline3 = new TimelineMax();

//     timeline3
//       .to( "#video", 1,
//       {
//           y: 0,
//           x: 380,
//           ease: Linear.easeNone,
//           autoAlpha: 1,
//       },"-=1")
//       .to(".product .img-arya", 1, {
//           css: { scaleX: 2.5, scaleY: 2.5, opacity: 1 }
//       }, "-=1")
//       .to(".images-wrapper.top", 1, {
//           height: 0
//       })
//       .to(".product .img-arya .blur", 1, { css: { opacity: 1 } }, "-=1");
  

//     const scene3 = new ScrollMagic.Scene({
//       triggerElement: ".scheda-prodotto",
//       triggerHook: 0,
//       offset: "1963",
//       duration: "768"
//     })
//     .setTween(timeline3)
//     .addTo(controller)
//     //.addIndicators()


//   const preloadVideo = (v, callback) => {
//     const ready = () => {
//       v.removeEventListener('canplaythrough', ready);

//       video.pause();
//       var i = setInterval(function() {
//         if (v.readyState > 3) {
//           clearInterval(i);
//           video.currentTime = 0;
//           callback();
//         }
//       }, 50);
//     };
//     v.addEventListener('canplaythrough', ready, false);
//     v.play();


//     setTimeout(function(){
//       if($("#particles-js")){
//         particlesJS("particles-js", {
//           particles: {
//             number: {
//               value: 10,
//               density: {
//                 enable: true,
//                 value_area: 600
//               }
//             },
//             color: {
//               value: "#85cee4"
//             },
//             shape: {
//               type: "circle",
//               stroke: {
//                 width: 0,
//                 color: "#000"
//               },
//               polygon: {
//                 nb_sides: 3
//               }
//             },
//             opacity: {
//               value: 0.4890406785639845,
//               random: true,
//               anim: {
//                 enable: true,
//                 speed: 0.08120772123013452,
//                 opacity_min: 0.09744926547616141,
//                 sync: false
//               }
//             },
//             size: {
//               value: 32.06824121731046,
//               random: true,
//               anim: {
//                 enable: true,
//                 speed: 12.181158184520175,
//                 size_min: 5.684540486109416,
//                 sync: false
//               }
//             },
//             line_linked: {
//               enable: false,
//               distance: 689.4671861721748,
//               color: "#ffffff",
//               opacity: 0.70550130678083,
//               width: 1.763753266952075
//             },
//             move: {
//               enable: true,
//               speed: 8,
//               direction: "none",
//               random: false,
//               straight: false,
//               out_mode: "out",
//               bounce: false,
//               attract: {
//                 enable: false,
//                 rotateX: 600,
//                 rotateY: 1200
//               }
//             }
//           },
//           retina_detect: true
//         });
//       }
  
//       if($("#particle-co2")){
//         particlesJS("particle-co2", {
//           particles: {
//             number: {
//               value: 5,
//               density: {
//                 enable: true,
//                 value_area: 500
//               }
//             },
//             color: {
//               value: "#1b1e34"
//             },
//             shape: {
//               type: "image",
//               image: {
//                 src: "../assets/Jeniot/images/ico-co2.gif",
//                 width: 42,
//                 height: 22
//               }
//             },
//             opacity: {
//               value: 0.4890406785639845,
//               random: true,
//               anim: {
//                 enable: true,
//                 speed: 0.08120772123013452,
//                 opacity_min: 0.09744926547616141,
//                 sync: false
//               }
//             },
//             size: {
//               value: 32.06824121731046,
//               random: true,
//               anim: {
//                 enable: true,
//                 speed: 12.181158184520175,
//                 size_min: 5.684540486109416,
//                 sync: false
//               }
//             },
//             move: {
//               enable: true,
//               speed: 8,
//               direction: "right",
//               random: true,
//               straight: false,
//               out_mode: "out",
//               bounce: false,
//               attract: {
//                 enable: false,
//                 rotateX: 600,
//                 rotateY: 1200
//               }
//             }
//           },
//           retina_detect: true
//         });
//       }
//     },50)
//   };

//   if(video){
//     preloadVideo(video, startScrollAnimation);
//   }


//   $('.animation-wrapper').show();




// });

// // startScrollAnimation();

// // $(window).on('load', function () {

// //   $('.preloader').fadeOut('slow');

// //   var images01 = [
// //     "../assets/Jeniot/images/arya-animation/0001.gif",
// //     "../assets/Jeniot/images/arya-animation/0002.gif",
// //     "../assets/Jeniot/images/arya-animation/0003.gif",
// //     "../assets/Jeniot/images/arya-animation/0004.gif",
// //     "../assets/Jeniot/images/arya-animation/0005.gif",
// //     "../assets/Jeniot/images/arya-animation/0006.gif",
// //     "../assets/Jeniot/images/arya-animation/0007.gif",
// //     "../assets/Jeniot/images/arya-animation/0008.gif",
// //     "../assets/Jeniot/images/arya-animation/0009.gif",
// //     "../assets/Jeniot/images/arya-animation/0010.gif",
// //     "../assets/Jeniot/images/arya-animation/0011.gif",
// //     "../assets/Jeniot/images/arya-animation/0012.gif",
// //     "../assets/Jeniot/images/arya-animation/0013.gif",
// //     "../assets/Jeniot/images/arya-animation/0014.gif",
// //     "../assets/Jeniot/images/arya-animation/0015.gif",
// //     "../assets/Jeniot/images/arya-animation/0016.gif",
// //     "../assets/Jeniot/images/arya-animation/0017.gif",
// //     "../assets/Jeniot/images/arya-animation/0018.gif",
// //     "../assets/Jeniot/images/arya-animation/0019.gif",
// //     "../assets/Jeniot/images/arya-animation/0020.gif",
// //     "../assets/Jeniot/images/arya-animation/0021.gif",
// //     "../assets/Jeniot/images/arya-animation/0022.gif",
// //     "../assets/Jeniot/images/arya-animation/0023.gif",
// //     "../assets/Jeniot/images/arya-animation/0024.gif",
// //     "../assets/Jeniot/images/arya-animation/0025.gif",
// //     "../assets/Jeniot/images/arya-animation/0026.gif",
// //     "../assets/Jeniot/images/arya-animation/0027.gif",
// //     "../assets/Jeniot/images/arya-animation/0028.gif",
// //     "../assets/Jeniot/images/arya-animation/0029.gif",
// //     "../assets/Jeniot/images/arya-animation/0030.gif",
// //     "../assets/Jeniot/images/arya-animation/0031.gif",
// //     "../assets/Jeniot/images/arya-animation/0032.gif",
// //     "../assets/Jeniot/images/arya-animation/0033.gif",
// //     "../assets/Jeniot/images/arya-animation/0034.gif",
// //     "../assets/Jeniot/images/arya-animation/0035.gif",
// //     "../assets/Jeniot/images/arya-animation/0036.gif",
// //     "../assets/Jeniot/images/arya-animation/0037.gif",
// //     "../assets/Jeniot/images/arya-animation/0038.gif",
// //     "../assets/Jeniot/images/arya-animation/0039.gif",
// //     "../assets/Jeniot/images/arya-animation/0040.gif"
// //   ];

// //     var images02 = [
// //     "../assets/Jeniot/images/arya-animation/0041.gif",
// //     "../assets/Jeniot/images/arya-animation/0042.gif",
// //     "../assets/Jeniot/images/arya-animation/0043.gif",
// //     "../assets/Jeniot/images/arya-animation/0044.gif",
// //     "../assets/Jeniot/images/arya-animation/0045.gif",
// //     "../assets/Jeniot/images/arya-animation/0046.gif",
// //     "../assets/Jeniot/images/arya-animation/0047.gif",
// //     "../assets/Jeniot/images/arya-animation/0048.gif",
// //     "../assets/Jeniot/images/arya-animation/0049.gif",
// //     "../assets/Jeniot/images/arya-animation/0050.gif",
// //     "../assets/Jeniot/images/arya-animation/0051.gif",
// //     "../assets/Jeniot/images/arya-animation/0052.gif",
// //     "../assets/Jeniot/images/arya-animation/0053.gif",
// //     "../assets/Jeniot/images/arya-animation/0054.gif",
// //     "../assets/Jeniot/images/arya-animation/0055.gif",
// //     "../assets/Jeniot/images/arya-animation/0056.gif",
// //     "../assets/Jeniot/images/arya-animation/0057.gif",
// //     "../assets/Jeniot/images/arya-animation/0058.gif",
// //     "../assets/Jeniot/images/arya-animation/0059.gif",
// //     "../assets/Jeniot/images/arya-animation/0060.gif",
// //     "../assets/Jeniot/images/arya-animation/0061.gif",
// //     "../assets/Jeniot/images/arya-animation/0062.gif",
// //     "../assets/Jeniot/images/arya-animation/0063.gif",
// //     "../assets/Jeniot/images/arya-animation/0064.gif",
// //     "../assets/Jeniot/images/arya-animation/0065.gif",
// //     "../assets/Jeniot/images/arya-animation/0066.gif",
// //     "../assets/Jeniot/images/arya-animation/0067.gif",
// //     "../assets/Jeniot/images/arya-animation/0068.gif",
// //     "../assets/Jeniot/images/arya-animation/0069.gif",
// //     "../assets/Jeniot/images/arya-animation/0070.gif",
// //     "../assets/Jeniot/images/arya-animation/0071.gif",
// //     "../assets/Jeniot/images/arya-animation/0072.gif",
// //     "../assets/Jeniot/images/arya-animation/0073.gif",
// //     "../assets/Jeniot/images/arya-animation/0074.gif",
// //     "../assets/Jeniot/images/arya-animation/0075.gif",
// //     "../assets/Jeniot/images/arya-animation/0076.gif",
// //     "../assets/Jeniot/images/arya-animation/0077.gif",
// //     "../assets/Jeniot/images/arya-animation/0078.gif",
// //     "../assets/Jeniot/images/arya-animation/0079.gif",
// //     "../assets/Jeniot/images/arya-animation/0080.gif"
// //   ];
// //   var images03 = [
// //     "../assets/Jeniot/images/arya-animation/0081.gif",
// //     "../assets/Jeniot/images/arya-animation/0082.gif",
// //     "../assets/Jeniot/images/arya-animation/0083.gif",
// //     "../assets/Jeniot/images/arya-animation/0084.gif",
// //     "../assets/Jeniot/images/arya-animation/0085.gif",
// //     "../assets/Jeniot/images/arya-animation/0086.gif",
// //     "../assets/Jeniot/images/arya-animation/0087.gif",
// //     "../assets/Jeniot/images/arya-animation/0088.gif",
// //     "../assets/Jeniot/images/arya-animation/0089.gif",
// //     "../assets/Jeniot/images/arya-animation/0090.gif",
// //     "../assets/Jeniot/images/arya-animation/0091.gif",
// //     "../assets/Jeniot/images/arya-animation/0092.gif",
// //     "../assets/Jeniot/images/arya-animation/0093.gif",
// //     "../assets/Jeniot/images/arya-animation/0094.gif",
// //     "../assets/Jeniot/images/arya-animation/0095.gif",
// //     "../assets/Jeniot/images/arya-animation/0096.gif",
// //     "../assets/Jeniot/images/arya-animation/0097.gif",
// //     "../assets/Jeniot/images/arya-animation/0098.gif",
// //     "../assets/Jeniot/images/arya-animation/0099.gif",
// //     "../assets/Jeniot/images/arya-animation/0100.gif",
// //     "../assets/Jeniot/images/arya-animation/0101.gif",
// //     "../assets/Jeniot/images/arya-animation/0102.gif",
// //     "../assets/Jeniot/images/arya-animation/0103.gif",
// //     "../assets/Jeniot/images/arya-animation/0104.gif",
// //     "../assets/Jeniot/images/arya-animation/0105.gif",
// //     "../assets/Jeniot/images/arya-animation/0106.gif",
// //     "../assets/Jeniot/images/arya-animation/0107.gif",
// //     "../assets/Jeniot/images/arya-animation/0108.gif",
// //     "../assets/Jeniot/images/arya-animation/0109.gif",
// //     "../assets/Jeniot/images/arya-animation/0110.gif",
// //     "../assets/Jeniot/images/arya-animation/0111.gif",
// //     "../assets/Jeniot/images/arya-animation/0112.gif",
// //     "../assets/Jeniot/images/arya-animation/0113.gif",
// //     "../assets/Jeniot/images/arya-animation/0114.gif",
// //     "../assets/Jeniot/images/arya-animation/0115.gif",
// //     "../assets/Jeniot/images/arya-animation/0116.gif",
// //     "../assets/Jeniot/images/arya-animation/0117.gif",
// //     "../assets/Jeniot/images/arya-animation/0118.gif",
// //     "../assets/Jeniot/images/arya-animation/0119.gif",
// //     "../assets/Jeniot/images/arya-animation/0120.gif",
// //   ];



// //     // "../assets/Jeniot/images/arya-animation/0121.gif",
// //     // "../assets/Jeniot/images/arya-animation/0122.gif",
// //     // "../assets/Jeniot/images/arya-animation/0123.gif",
// //     // "../assets/Jeniot/images/arya-animation/0124.gif",
// //     // "../assets/Jeniot/images/arya-animation/0125.gif",
// //     // "../assets/Jeniot/images/arya-animation/0126.gif",
// //     // "../assets/Jeniot/images/arya-animation/0127.gif",
// //     // "../assets/Jeniot/images/arya-animation/0128.gif",
// //     // "../assets/Jeniot/images/arya-animation/0129.gif",
// //     // "../assets/Jeniot/images/arya-animation/0130.gif",
// //     // "../assets/Jeniot/images/arya-animation/0131.gif",
// //     // "../assets/Jeniot/images/arya-animation/0132.gif",
// //     // "../assets/Jeniot/images/arya-animation/0133.gif",
// //     // "../assets/Jeniot/images/arya-animation/0134.gif",
// //     // "../assets/Jeniot/images/arya-animation/0135.gif",
// //     // "../assets/Jeniot/images/arya-animation/0136.gif",
// //     // "../assets/Jeniot/images/arya-animation/0137.gif",
// //     // "../assets/Jeniot/images/arya-animation/0138.gif",
// //     // "../assets/Jeniot/images/arya-animation/0139.gif",
// //     // "../assets/Jeniot/images/arya-animation/0140.gif",
// //     // "../assets/Jeniot/images/arya-animation/0141.gif",
// //     // "../assets/Jeniot/images/arya-animation/0142.gif",
// //     // "../assets/Jeniot/images/arya-animation/0143.gif",
// //     // "../assets/Jeniot/images/arya-animation/0144.gif",
// //     // "../assets/Jeniot/images/arya-animation/0145.gif",
// //     // "../assets/Jeniot/images/arya-animation/0146.gif",
// //     // "../assets/Jeniot/images/arya-animation/0147.gif",
// //     // "../assets/Jeniot/images/arya-animation/0148.gif",
// //     // "../assets/Jeniot/images/arya-animation/0149.gif",
// //     // "../assets/Jeniot/images/arya-animation/0150.gif",
// //     // "../assets/Jeniot/images/arya-animation/0151.gif",
// //     // "../assets/Jeniot/images/arya-animation/0152.gif",
// //     // "../assets/Jeniot/images/arya-animation/0153.gif",
// //     // "../assets/Jeniot/images/arya-animation/0154.gif",
// //     // "../assets/Jeniot/images/arya-animation/0155.gif",
// //     // "../assets/Jeniot/images/arya-animation/0156.gif",
// //     // "../assets/Jeniot/images/arya-animation/0157.gif",
// //     // "../assets/Jeniot/images/arya-animation/0158.gif",
// //     // "../assets/Jeniot/images/arya-animation/0159.gif",
// //     // "../assets/Jeniot/images/arya-animation/0160.gif",
// //     // "../assets/Jeniot/images/arya-animation/0161.gif",
// //     // "../assets/Jeniot/images/arya-animation/0162.gif",
// //     // "../assets/Jeniot/images/arya-animation/0163.gif",
// //     // "../assets/Jeniot/images/arya-animation/0164.gif",
// //     // "../assets/Jeniot/images/arya-animation/0165.gif",
// //     // "../assets/Jeniot/images/arya-animation/0166.gif",
// //     // "../assets/Jeniot/images/arya-animation/0167.gif",
// //     // "../assets/Jeniot/images/arya-animation/0168.gif",
// //     // "../assets/Jeniot/images/arya-animation/0169.gif",
// //     // "../assets/Jeniot/images/arya-animation/0170.gif",
// //     // "../assets/Jeniot/images/arya-animation/0171.gif",
// //     // "../assets/Jeniot/images/arya-animation/0172.gif",
// //     // "../assets/Jeniot/images/arya-animation/0173.gif",
// //     // "../assets/Jeniot/images/arya-animation/0174.gif",
// //     // "../assets/Jeniot/images/arya-animation/0175.gif",
// //     // "../assets/Jeniot/images/arya-animation/0176.gif",
// //     // "../assets/Jeniot/images/arya-animation/0177.gif",
// //     // "../assets/Jeniot/images/arya-animation/0178.gif",
// //     // "../assets/Jeniot/images/arya-animation/0179.gif",
// //     // "../assets/Jeniot/images/arya-animation/0180.gif",
// //     // "../assets/Jeniot/images/arya-animation/0181.gif",
// //     // "../assets/Jeniot/images/arya-animation/0182.gif",
// //     // "../assets/Jeniot/images/arya-animation/0183.gif",
// //     // "../assets/Jeniot/images/arya-animation/0184.gif",
// //     // "../assets/Jeniot/images/arya-animation/0185.gif",
// //     // "../assets/Jeniot/images/arya-animation/0186.gif",
// //     // "../assets/Jeniot/images/arya-animation/0187.gif",
// //     // "../assets/Jeniot/images/arya-animation/0188.gif",
// //     // "../assets/Jeniot/images/arya-animation/0189.gif",
// //     // "../assets/Jeniot/images/arya-animation/0190.gif",
// //     // "../assets/Jeniot/images/arya-animation/0191.gif",
// //     // "../assets/Jeniot/images/arya-animation/0192.gif",
// //     // "../assets/Jeniot/images/arya-animation/0193.gif",
// //     // "../assets/Jeniot/images/arya-animation/0194.gif",
// //     // "../assets/Jeniot/images/arya-animation/0195.gif",
// //     // "../assets/Jeniot/images/arya-animation/0196.gif",
// //     // "../assets/Jeniot/images/arya-animation/0197.gif",
// //     // "../assets/Jeniot/images/arya-animation/0198.gif",
// //     // "../assets/Jeniot/images/arya-animation/0199.gif",
// //     // "../assets/Jeniot/images/arya-animation/0200.gif"
// //   // ];

// //   // var images03 = [
// //   //   "../assets/Jeniot/images/arya-animation/0201.gif",
// //   //   "../assets/Jeniot/images/arya-animation/0202.gif",
// //   //   "../assets/Jeniot/images/arya-animation/0203.gif",
// //   //   "../assets/Jeniot/images/arya-animation/0204.gif",
// //   //   "../assets/Jeniot/images/arya-animation/0205.gif",
// //   //   "../assets/Jeniot/images/arya-animation/0206.gif",
// //   //   "../assets/Jeniot/images/arya-animation/0207.gif",
// //   //   "../assets/Jeniot/images/arya-animation/0208.gif",
// //   //   "../assets/Jeniot/images/arya-animation/0209.gif",
// //   //   "../assets/Jeniot/images/arya-animation/0210.gif",
// //     // "../assets/Jeniot/images/arya-animation/0211.gif",
// //     // "../assets/Jeniot/images/arya-animation/0212.gif",
// //     // "../assets/Jeniot/images/arya-animation/0213.gif",
// //     // "../assets/Jeniot/images/arya-animation/0214.gif",
// //     // "../assets/Jeniot/images/arya-animation/0215.gif",
// //     // "../assets/Jeniot/images/arya-animation/0216.gif",
// //     // "../assets/Jeniot/images/arya-animation/0217.gif",
// //     // "../assets/Jeniot/images/arya-animation/0218.gif",
// //     // "../assets/Jeniot/images/arya-animation/0219.gif",
// //     // "../assets/Jeniot/images/arya-animation/0220.gif",
// //     // "../assets/Jeniot/images/arya-animation/0221.gif",
// //     // "../assets/Jeniot/images/arya-animation/0222.gif",
// //     // "../assets/Jeniot/images/arya-animation/0223.gif",
// //     // "../assets/Jeniot/images/arya-animation/0224.gif",
// //     // "../assets/Jeniot/images/arya-animation/0225.gif",
// //     // "../assets/Jeniot/images/arya-animation/0226.gif",
// //     // "../assets/Jeniot/images/arya-animation/0227.gif",
// //     // "../assets/Jeniot/images/arya-animation/0228.gif",
// //     // "../assets/Jeniot/images/arya-animation/0229.gif",
// //     // "../assets/Jeniot/images/arya-animation/0230.gif",
// //     // "../assets/Jeniot/images/arya-animation/0231.gif",
// //     // "../assets/Jeniot/images/arya-animation/0232.gif",
// //     // "../assets/Jeniot/images/arya-animation/0233.gif",
// //     // "../assets/Jeniot/images/arya-animation/0234.gif",
// //     // "../assets/Jeniot/images/arya-animation/0235.gif",
// //     // "../assets/Jeniot/images/arya-animation/0236.gif",
// //     // "../assets/Jeniot/images/arya-animation/0237.gif",
// //     // "../assets/Jeniot/images/arya-animation/0238.gif",
// //     // "../assets/Jeniot/images/arya-animation/0239.gif",
// //     // "../assets/Jeniot/images/arya-animation/0240.gif",
// //     // "../assets/Jeniot/images/arya-animation/0241.gif",
// //     // "../assets/Jeniot/images/arya-animation/0242.gif",
// //     // "../assets/Jeniot/images/arya-animation/0243.gif",
// //     // "../assets/Jeniot/images/arya-animation/0244.gif",
// //     // "../assets/Jeniot/images/arya-animation/0245.gif",
// //     // "../assets/Jeniot/images/arya-animation/0246.gif",
// //     // "../assets/Jeniot/images/arya-animation/0247.gif",
// //     // "../assets/Jeniot/images/arya-animation/0248.gif",
// //     // "../assets/Jeniot/images/arya-animation/0249.gif",
// //     // "../assets/Jeniot/images/arya-animation/0250.gif",
// //     // "../assets/Jeniot/images/arya-animation/0251.gif",
// //     // "../assets/Jeniot/images/arya-animation/0252.gif",
// //     // "../assets/Jeniot/images/arya-animation/0253.gif",
// //     // "../assets/Jeniot/images/arya-animation/0254.gif",
// //     // "../assets/Jeniot/images/arya-animation/0255.gif",
// //     // "../assets/Jeniot/images/arya-animation/0256.gif",
// //     // "../assets/Jeniot/images/arya-animation/0257.gif",
// //     // "../assets/Jeniot/images/arya-animation/0258.gif",
// //     // "../assets/Jeniot/images/arya-animation/0259.gif",
// //     // "../assets/Jeniot/images/arya-animation/0260.gif",
// //     // "../assets/Jeniot/images/arya-animation/0261.gif",
// //     // "../assets/Jeniot/images/arya-animation/0262.gif",
// //     // "../assets/Jeniot/images/arya-animation/0263.gif",
// //     // "../assets/Jeniot/images/arya-animation/0264.gif",
// //     // "../assets/Jeniot/images/arya-animation/0265.gif",
// //     // "../assets/Jeniot/images/arya-animation/0266.gif",
// //     // "../assets/Jeniot/images/arya-animation/0267.gif",
// //     // "../assets/Jeniot/images/arya-animation/0268.gif",
// //     // "../assets/Jeniot/images/arya-animation/0269.gif",
// //     // "../assets/Jeniot/images/arya-animation/0270.gif",
// //     // "../assets/Jeniot/images/arya-animation/0271.gif",
// //     // "../assets/Jeniot/images/arya-animation/0272.gif",
// //     // "../assets/Jeniot/images/arya-animation/0273.gif",
// //     // "../assets/Jeniot/images/arya-animation/0274.gif",
// //     // "../assets/Jeniot/images/arya-animation/0275.gif",
// //     // "../assets/Jeniot/images/arya-animation/0276.gif",
// //     // "../assets/Jeniot/images/arya-animation/0277.gif",
// //     // "../assets/Jeniot/images/arya-animation/0278.gif",
// //     // "../assets/Jeniot/images/arya-animation/0279.gif",
// //     // "../assets/Jeniot/images/arya-animation/0280.gif",
// //     // "../assets/Jeniot/images/arya-animation/0281.gif",
// //     // "../assets/Jeniot/images/arya-animation/0282.gif",
// //     // "../assets/Jeniot/images/arya-animation/0283.gif",
// //     // "../assets/Jeniot/images/arya-animation/0284.gif",
// //     // "../assets/Jeniot/images/arya-animation/0285.gif",
// //     // "../assets/Jeniot/images/arya-animation/0286.gif",
// //     // "../assets/Jeniot/images/arya-animation/0287.gif",
// //     // "../assets/Jeniot/images/arya-animation/0288.gif",
// //     // "../assets/Jeniot/images/arya-animation/0289.gif",
// //     // "../assets/Jeniot/images/arya-animation/0290.gif",
// //     // "../assets/Jeniot/images/arya-animation/0291.gif",
// //     // "../assets/Jeniot/images/arya-animation/0292.gif",
// //     // "../assets/Jeniot/images/arya-animation/0293.gif",
// //     // "../assets/Jeniot/images/arya-animation/0294.gif",
// //     // "../assets/Jeniot/images/arya-animation/0295.gif",
// //     // "../assets/Jeniot/images/arya-animation/0296.gif",
// //     // "../assets/Jeniot/images/arya-animation/0297.gif",
// //     // "../assets/Jeniot/images/arya-animation/0298.gif",
// //     // "../assets/Jeniot/images/arya-animation/0299.gif",
// //     // "../assets/Jeniot/images/arya-animation/0300.gif",
// //   // ];

// //   // TweenMax can tween any property of any object. We use this object to cycle through the array
// //   var obj01 = {curImg01: 0};
// //   var obj02 = {curImg02: 0};
// //   var obj03 = {curImg03: 0};

// //   let timeline = new TimelineMax();
// //   let timeline2 = new TimelineMax();
// //   let timeline3 = new TimelineMax();

// //   let animationSteps01 = 40;
// //   let animationSteps02 = 40;
// //   let animationSteps03 = 40;

// //   // create tween
// //   timeline
// //     .to( obj01, 1,
// //     {
// //         curImg01: images01.length - 1,	// animate propery curImg to number of images
// //         roundProps: "curImg01",				// only integers so it can be used as an array index
// //         //repeat: 3,									// repeat 3 times
// //         immediateRender: true,			// load first image automatically
// //         ease: Linear.easeNone,			// show every image the same ammount of time
// //         onUpdate: function () {
// //           $("#myimg").attr("src", images01[obj01.curImg01]); // set the image source
// //         }
// //     })
// //     .to( "#imagesequence", 1,
// //     {
// //         y: 895,
// //         x: 0,
// //         ease: Linear.easeNone,
// //         autoAlpha: 1,
// //     },"-=1")

// //   timeline2
// //     .to( obj02, 1,
// //     {
// //         curImg02: images02.length - 1,	// animate propery curImg to number of images
// //         roundProps: "curImg02",				// only integers so it can be used as an array index
// //         //repeat: 3,									// repeat 3 times
// //         //immediateRender: true,			// load first image automatically
// //         ease: Linear.easeNone,			// show every image the same ammount of time
// //         onUpdate: function () {
// //           $("#myimg").attr("src", images02[obj02.curImg02]); // set the image source
// //         }
// //     })
// //     .to( "#imagesequence", 1,
// //     {
// //         y: 1663,
// //         x: 0,
// //         ease: Linear.easeNone,
// //         autoAlpha: 1,
// //     },"-=1")
    
// //   timeline3
// //     .to( obj03, 1,
// //     {
// //         curImg03: images03.length - 1,	// animate propery curImg to number of images
// //         roundProps: "curImg03",				// only integers so it can be used as an array index
// //         //repeat: 3,									// repeat 3 times
// //         //immediateRender: true,			// load first image automatically
// //         ease: Linear.easeNone,			// show every image the same ammount of time
// //         onUpdate: function () {
// //           $("#myimg").attr("src", images03[obj03.curImg03]); // set the image source
// //         }
// //     })
// //     .to( "#imagesequence", 1,
// //     {
// //         y: 2300,
// //         x: 380,
// //         ease: Linear.easeNone,
// //         autoAlpha: 1,
// //     },"-=1")
// //     .to(".product .img-arya", 1, {
// //         css: { scaleX: 2.5, scaleY: 2.5, opacity: 1 }
// //     }, "-=1")
// //     .to(".images-wrapper.top", 1, {
// //         height: 0
// //     })
// //     .to(".product .img-arya .blur", 1, { css: { opacity: 1 } }, "-=1");


// //         // .to(
// //     //     "#imagesequence",
// //     //     1,
// //     //     {
// //     //       y: 1000,
// //     //       x: 0,
// //     //       ease: Linear.easeNone,
// //     //     },
// //     //     "-=1"
// //     // )

// //   // timeline2.to(
// //   //         "#imagesequence",
// //   //         1,
// //   //         {
// //   //             y: 0,
// //   //             x: 500,
// //   //             ease: Linear.easeNone,
// //   //             autoAlpha: 0
// //   //         })
// //   // init controller
// //   var controller = new ScrollMagic.Controller();

// //   // build scene
// //   // var scene = new ScrollMagic.Scene({triggerElement: ".scheda-prodotto", duration: "100%", offset: "10%"})
// //   //     .setTween(timeline)
// //   //     //- //- .addIndicators() // add indicators (requires plugin)
// //   //     .setPin('#imagesequence')
// //   //     .addTo(controller);

// //   // let scene = new ScrollMagic.Scene({
// //   //     triggerElement: ".scheda-prodotto",
// //   //     triggerHook: 0,
// //   //     offset: "300",
// //   //     duration: "2132",
// //   // })
// //   // .setTween(timeline)
// //   // // .setTween(timeline2)
// //   // // .setTween(timeline3)
// //   // //- //- .addIndicators() // add indicators (requires plugin)
// //   // .setPin('#imagesequence')
// //   // .addTo(controller);


// //   let scene = new ScrollMagic.Scene({
// //     triggerElement: ".scheda-prodotto",
// //     triggerHook: 0,
// //     offset: "300",
// //     duration: "895",
// //   })
// //   .setTween(timeline)
// //   //- //- .addIndicators() // add indicators (requires plugin)
// //   //.setPin('#imagesequence')
// //   .addTo(controller);

// //   let scene2 = new ScrollMagic.Scene({
// //     triggerElement: ".scheda-prodotto",
// //     triggerHook: 0,
// //     offset: "1195",
// //     duration: "768",
// //     //height: "798px"
// //     //height: 3621px;
// //   })
// //   .setTween(timeline2)
// //   //.setPin('#imagesequence')
// //   //- //- .addIndicators() // add indicators (requires plugin)
// //   .addTo(controller);

// //   let scene3 = new ScrollMagic.Scene({
// //     triggerElement: ".scheda-prodotto",
// //     triggerHook: 0,
// //     offset: "1963",
// //     duration: "768",
// //   })
// //   .setTween(timeline3)
// //   //.setPin('#imagesequence')
// //   //- //- .addIndicators() // add indicators (requires plugin)
// //   .addTo(controller);

// //   // let scene3 = new ScrollMagic.Scene({
// //   //     triggerElement: ".smart-light-section",
// //   //     duration: "20%",
// //   //     triggerHook: 0.15,
// //   //     offset: "0"
// //   // })
// //   // .setTween(timeline3)
// //   // .addIndicators() // add indicators (requires plugin)
// //   // .addTo(controller);


// //   $('.animation-wrapper').show();


// // setTimeout(function(){
// //   if($("#particles-js")){
// //     particlesJS("particles-js", {
// //       particles: {
// //         number: {
// //           value: 10,
// //           density: {
// //             enable: true,
// //             value_area: 600
// //           }
// //         },
// //         color: {
// //           value: "#85cee4"
// //         },
// //         shape: {
// //           type: "circle",
// //           stroke: {
// //             width: 0,
// //             color: "#000"
// //           },
// //           polygon: {
// //             nb_sides: 3
// //           }
// //         },
// //         opacity: {
// //           value: 0.4890406785639845,
// //           random: true,
// //           anim: {
// //             enable: true,
// //             speed: 0.08120772123013452,
// //             opacity_min: 0.09744926547616141,
// //             sync: false
// //           }
// //         },
// //         size: {
// //           value: 32.06824121731046,
// //           random: true,
// //           anim: {
// //             enable: true,
// //             speed: 12.181158184520175,
// //             size_min: 5.684540486109416,
// //             sync: false
// //           }
// //         },
// //         line_linked: {
// //           enable: false,
// //           distance: 689.4671861721748,
// //           color: "#ffffff",
// //           opacity: 0.70550130678083,
// //           width: 1.763753266952075
// //         },
// //         move: {
// //           enable: true,
// //           speed: 8,
// //           direction: "none",
// //           random: false,
// //           straight: false,
// //           out_mode: "out",
// //           bounce: false,
// //           attract: {
// //             enable: false,
// //             rotateX: 600,
// //             rotateY: 1200
// //           }
// //         }
// //       },
// //       retina_detect: true
// //     });
// //   }

// //   if($("#particle-co2")){
// //     particlesJS("particle-co2", {
// //       particles: {
// //         number: {
// //           value: 5,
// //           density: {
// //             enable: true,
// //             value_area: 500
// //           }
// //         },
// //         color: {
// //           value: "#1b1e34"
// //         },
// //         shape: {
// //           type: "image",
// //           image: {
// //             src: "../assets/Jeniot/images/ico-co2.gif",
// //             width: 42,
// //             height: 22
// //           }
// //         },
// //         opacity: {
// //           value: 0.4890406785639845,
// //           random: true,
// //           anim: {
// //             enable: true,
// //             speed: 0.08120772123013452,
// //             opacity_min: 0.09744926547616141,
// //             sync: false
// //           }
// //         },
// //         size: {
// //           value: 32.06824121731046,
// //           random: true,
// //           anim: {
// //             enable: true,
// //             speed: 12.181158184520175,
// //             size_min: 5.684540486109416,
// //             sync: false
// //           }
// //         },
// //         move: {
// //           enable: true,
// //           speed: 8,
// //           direction: "right",
// //           random: true,
// //           straight: false,
// //           out_mode: "out",
// //           bounce: false,
// //           attract: {
// //             enable: false,
// //             rotateX: 600,
// //             rotateY: 1200
// //           }
// //         }
// //       },
// //       retina_detect: true
// //     });
// //   }
// // },50)


// // });

