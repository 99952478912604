$( document ).ready(function() {

var controller = new ScrollMagic.Controller();

$( ".scroll-panel" ).each(function( index ) {

    let scrollPanelWrapperOLDBG = $(this).find(".scroll-panel-common .oldbg");
    let scrollPanelWrapperNEWBG = $(this).find(".scroll-panel-common .newbg");
    let ns = $(this).find(".scroll-panel-wrapper .content .row").length;
    let perc = ((ns - 1)*100);

    $(this).find(".scroll-panel-common .oldbg").css("background-image", 'url(' + $(this).find(".scroll-panel-wrapper-box").eq(0).find(".bg").attr("src") + ')');

    var tl = new TimelineMax();
    tl.to($(this).find(".scroll-panel-wrapper .content"), 1, {y: '-' + perc + '%'});

    new ScrollMagic.Scene({
    triggerElement: this,
    triggerHook: 0,
    duration: "100%"
    })
    .setPin(this)
    .setTween(tl)
    //   .addIndicators({
    //   colorTrigger: "black",
    //   colorStart: "black",
    //   colorEnd: "black",
    //   indent: 40
    // })
    .addTo(controller);

    var tl2 = new TimelineMax();

    for(var i = 0; i < ns - 1; i++){
        tl2.set(scrollPanelWrapperOLDBG, { autoAlpha: 1});
        tl2.set(scrollPanelWrapperNEWBG, { autoAlpha: 0});

        tl2.set(scrollPanelWrapperOLDBG, {backgroundImage:'url(' + $(this).find(".scroll-panel-wrapper-box").eq(i).find(".bg").attr("src") + ')'});
        tl2.set(scrollPanelWrapperNEWBG, {backgroundImage:'url(' + $(this).find(".scroll-panel-wrapper-box").eq(i + 1).find(".bg").attr("src") + ')'});

        tl2.to(scrollPanelWrapperOLDBG, 1,{ autoAlpha: 0, ease: Linear.easeNone});
        tl2.to(scrollPanelWrapperNEWBG, 1,{ autoAlpha: 1, ease: Linear.easeNone}, "-=1");

    }

    new ScrollMagic.Scene({
        triggerElement: this,
        triggerHook: 0,
        duration: "70%",
        offset: "0"
    })
    .setTween(tl2)
    //   .addIndicators({
    //   colorTrigger: "violet",
    //   colorStart: "violet",
    //   colorEnd: "violet",
    //   indent: 100})
    .addTo(controller);

}); 


});
