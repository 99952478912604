$(document).ready(function(e) {

  function Bgmq() {
    var bodyWidth = document.body.clientWidth;
    var bodyHeight = $(window).height();

    if (bodyWidth) {
      $(".bgmq").each(function() {

        let img = "";

        if (bodyWidth < 768) {
            img = $(this).attr("data-mobile");
        }
        if (bodyWidth >= 768 && bodyWidth < 990) {
            img = $(this).attr("data-tabletPort");
        }
        if (bodyWidth >= 990 && bodyWidth < 1400) {
           img = $(this).attr("data-tabletLand");
        }
        if (bodyWidth >= 1400) {
           img = $(this).attr("data-desktop");
        }
        if($(this).hasClass("srcmq")){
            //console.log("srcmq")
            $(this).attr("src", img);
        }
        else{
            //console.log(".bgmq")
            var styles = {
                backgroundImage:
                    'linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("' +
                    img +
                    '")'
            };
            $(this).css(styles);
        }
        

      });
    } else {
      window.setTimeout(Bgmq, 30);
    }
  }


  Bgmq()

  $(window).bind("load", Bgmq);
  $(window).bind("resize", Bgmq);
  $(window).bind("orientationchange", Bgmq);
});
