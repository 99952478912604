// Start Module: Scheda Prodotto
(function() {

  //------------------------------------------
  // JS per header sticky
  //------------------------------------------

  $(window).scroll(function() {
    if (window.matchMedia("(min-width: 990px)").matches) {
      //console.log(">=990px");
      if ($(this).scrollTop() > $(".header").outerHeight()) {
        $(".product-header-info").addClass("sticky");
        $("main").css(
          "margin-top",
          $(".product-header-info").outerHeight(true)
        );
      } else {
        $(".product-header-info").removeClass("sticky");
        $("main").css("margin-top", "0");
      }
    } else {
      //console.log("<990px");
    }
  });


  //------------------------------------------
  // JS per modulo scheda prodotto light color changer
  //------------------------------------------

  let hue = 130;
  let sat = 100;
  let light = 50;
  let alpha = 1;

  const colorChange = () => {
    var blurRadius = 50;
    if ($(".arya-blur"))
      $(".arya-blur svg").css({
        "-webkit-filter": "blur(" + blurRadius + "px)",
        filter: "blur(" + blurRadius + "px)"
      });
    if ($(".blob")) $(".blob svg path").css({ fill: getHSL(), opacity: "0.1" });
    if ($(".text-block")) $(".text-block .title").css("color", getHSL());
    if ($(".arya-blur")) $(".arya-blur svg ellipse").css("fill", getHSL());
  };
  const getHSL = () => {
    return `hsla(${hue}, ${sat}%, ${light}%, ${alpha})`;
  };

  const main = () => {
    const hueInput = document.querySelector("input[name=hue]");
    if(hueInput){
        hueInput.addEventListener("input", () => {
        hue = hueInput.value;
        colorChange();
      });
    }

    colorChange();
  };

  document.addEventListener("DOMContentLoaded", main);


  //------------------------------------------
  // JS per fading messagges
  //------------------------------------------

  // function whichAnimationEvent(){
  //   var t,
  //       el = document.createElement("fakeelement");
  
  //   var animations = {
  //     "animation"      : "animationend",
  //     "OAnimation"     : "oAnimationEnd",
  //     "MozAnimation"   : "animationend",
  //     "WebkitAnimation": "webkitAnimationEnd"
  //   }
  
  //   for (t in animations){
  //     if (el.style[t] !== undefined){
  //       return animations[t];
  //     }
  //   }
  // }
  
  // var animationEvent = whichAnimationEvent();

  // //------------------------------------------
  // // JS per fading messagges A
  // //------------------------------------------

  // var fillMex = function fillMex(target, arr, i) {
  //   target.find(".type").text(arr.eq(i).find(".type").text());
  //   target.find(".time").text(arr.eq(i).find(".time").text());
  //   target.find(".title").text(arr.eq(i).find(".title").text());
  //   target.find(".mex").text(arr.eq(i).find(".mex").text());
  // };

  // var ifca = 0;
  // var arra = $(".fading-mex-wrapper.a-type .contents li");
  // var arrLena = $(".fading-mex-wrapper.a-type .contents li").length;
  // fillMex($(".fading-mex-wrapper.a-type .fading-mex"), arra, ifca);
  // fillMex($(".fading-mex-wrapper.a-type .faded-mex"), arra, arrLena - 1); // fillMex($(".hidden-mex"), arr, ifc);

  // function fadeAllA() {
  //   $(".fading-mex-wrapper.a-type .fading-mex").unbind("click");
  //   $(".fading-mex-wrapper.a-type").append('<div class="hidden-mex"><div class="header"><div class="left"><div class="ico"> </div><div class="type"></div></div><div class="right"><div class="time"></div></div></div><div class="content"><h3 class="title"></h3><p class="mex"></p></div></div>');
  //   ifca++;
  //   fillMex($(".fading-mex-wrapper.a-type .hidden-mex"), arra, ifca);
  //   $(".fading-mex-wrapper.a-type .fading-mex").addClass("fade-out-top");
  //   $(".fading-mex-wrapper.a-type .faded-mex").addClass("fade-out");
  //   setTimeout(function () {
  //     $(".fading-mex-wrapper.a-type .faded-mex").removeClass("fade-out").remove();
  //     $(".fading-mex-wrapper.a-type .fading-mex").removeClass("fade-out-top").removeClass("fading-mex").addClass("faded-mex");
  //     $(".fading-mex-wrapper.a-type .hidden-mex").removeClass("hidden-mex").addClass("fading-mex").bind("click", function () {
  //       fadeAllA();
  //     });
  //     if (ifca >= arrLena - 1) ifca = -1;
  //   }, 2000);
  // }

  // $(".fading-mex-wrapper.a-type .fading-mex").on("click", function (event) {
  //   fadeAllA();
  // });

  // //------------------------------------------
  // // JS per fading messagges B
  // //------------------------------------------

  // var ifcb = 0;
  // var arrb = $(".fading-mex-wrapper.b-type .contents li");
  // var arrLenb = $(".fading-mex-wrapper.b-type .contents li").length;
  // fillMex($(".fading-mex-wrapper.b-type .fading-mex"), arrb, ifcb);
  // fillMex($(".fading-mex-wrapper.b-type .faded-mex"), arrb, arrLenb - 1); // fillMex($(".hidden-mex"), arr, ifc);

  // function fadeAllB() {
  //   $(".fading-mex-wrapper.b-type .fading-mex").unbind("click");
  //   $(".fading-mex-wrapper.b-type").append('<div class="hidden-mex"><div class="header"><div class="left"><div class="ico"> </div><div class="type"></div></div><div class="right"><div class="time"></div></div></div><div class="content"><h3 class="title"></h3><p class="mex"></p></div></div>');
  //   ifcb++;
  //   fillMex($(".fading-mex-wrapper.b-type .hidden-mex"), arrb, ifcb);
  //   $(".fading-mex-wrapper.b-type .fading-mex").addClass("fade-out-top");
  //   $(".fading-mex-wrapper.b-type .faded-mex").addClass("fade-out");
  //   setTimeout(function () {
  //     $(".fading-mex-wrapper.b-type .faded-mex").removeClass("fade-out").remove();
  //     $(".fading-mex-wrapper.b-type .fading-mex").removeClass("fade-out-top").removeClass("fading-mex").addClass("faded-mex");
  //     $(".fading-mex-wrapper.b-type .hidden-mex").removeClass("hidden-mex").addClass("fading-mex").bind("click", function () {
  //       fadeAllB();
  //     });
  //     if (ifcb >= arrLenb - 1) ifcb = -1;
  //   }, 2000);
  // }

  // $(".fading-mex-wrapper.b-type .fading-mex").on("click", function (event) {
  //   fadeAllB();
  // });


  //------------------------------------------
  // JS per accordion pagina dettaglio
  //------------------------------------------

  $(".product-accordion").accordion({
    active: false,
    collapsible: true,
    heightStyle: "content"
  });

  //------------------------------------------
  // JS per dettaglio foto prodotto, magnifier e player video
  //------------------------------------------

  var instance = $(".xzoom-lg").xzoom({
    zoomWidth: 400,
    title: true,
    tint: "#333",
    Xoffset: 15
  });
  $(".xzoom-gallery-lg").each(function(i, e) {
    instance.xappend($(e));
  });

  $(".kalturaPlayer").each(function() {
    kWidget.embed({
      targetId: $(this).attr("id"),
      wid: "_243342",
      uiconf_id: 20540612,
      cache_st: 1437579475,
      flashvars: {
        controlBarContainer: {
          hover: false
        }
      },
      entry_id: $(this).data("video")
    });
  });

  $(".kalturaPlayer").show();

  $(".video-thumb.kaltura").click(function() {
    $(".xzoom-lg").hide();
    $(".kalturaPlayer").show();
    $(".player-wrap-kaltura").show();
    $(".player-wrap-youtube").hide();
    $(".video-thumb.kaltura").addClass(" xactive");
    $(".xzoom-gallery-lg, .video-thumb.youtube").removeClass(" xactive");
  });
  $(".video-thumb.youtube").click(function() {
    $(".xzoom-lg").hide();
    $(".kalturaPlayer").show();
    $(".player-wrap-kaltura").hide();
    $(".player-wrap-youtube").show();
    $(".video-thumb.youtube").addClass(" xactive");
    $(".xzoom-gallery-lg, .video-thumb.kaltura").removeClass(" xactive");
  });
  $(".xzoom-gallery-lg").click(function() {
    $(".kalturaPlayer").hide();
    $(".player-wrap").hide();
    $(".xzoom-lg").show();
    $(".video-thumb").removeClass(" xactive");
  });

})();

// End Module: Scheda Prodotto
