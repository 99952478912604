$(window).on("load", function() {
  $(".preloader").fadeOut("slow");

  const controller = new ScrollMagic.Controller();

  //   var mqM = window.matchMedia("(min-width: 768px)");

  // var mqMO.addListener(createTimeline);
  //     mqTP.addListener(createTimeline);
  //     mqTL.addListener(createTimeline);
  //     mqDE.addListener(createTimeline);

  function createTimeline(e) {
    //console.log("------>>> createTimeline <<<------");

    let timeline1 = new TimelineMax();
    let timeline2 = new TimelineMax();
    let timeline3 = new TimelineMax();
    let timeline4 = new TimelineMax();
    let timelineFadingMexA = new TimelineMax();
    let timelineFadingMexB = new TimelineMax();

    if (window.matchMedia("(max-width: 767px)").matches) {
      timeline1.kill();
      timeline2.kill();
      timeline3.kill();
      timeline4.kill();
      timelineFadingMexA.kill();
      timelineFadingMexB.kill();
      //console.log("****** MOBILE ******");

      //-------------------------------------------------------------------------------------------------------------------------------
      //MOBILE TIMELINE
      //-------------------------------------------------------------------------------------------------------------------------------

      timeline1 = new TimelineMax();

      timeline1
        .from(".text-block-A", 1, {
          y: "100",
          x: "0",
          ease: Linear.easeNone,
          autoAlpha: 0
        })
        .to(
          ".arya-img-001",
          1,
          {
            scale: 1.2
          },
          "-=1"
        );

      timeline2 = new TimelineMax();
      timeline2
        .to(".text-block-A", 1, {
          y: "-100",
          x: "0",
          ease: Linear.easeNone,
          autoAlpha: 0
        })
        .from(".text-block-B", 1, {
          y: "100",
          x: "0",
          ease: Linear.easeNone,
          autoAlpha: 0
        })
        .to(
          ".arya-img-002",
          1,
          {
            scale: 1.2
          },
          "-=1"
        );

      timeline3 = new TimelineMax();

      timeline3
        .to("#particle-co2", 1, {
          autoAlpha: 1
        })
        .to(".text-block-B", 1, {
          y: "-100",
          x: "0",
          ease: Linear.easeNone,
          autoAlpha: 0
        })
        .from(".text-block-C", 1, {
          y: "100",
          x: "0",
          ease: Linear.easeNone,
          autoAlpha: 0
        })
        .to(
          ".arya-img-003",
          1,
          {
            scale: 1.2
          },
          "-=1"
        );

      timeline4 = new TimelineMax();

      timeline4
        .to(".text-block-C", 1, {
          y: "-100",
          x: "0",
          ease: Linear.easeNone,
          autoAlpha: 0
        })
        .to(".product .img-arya", 1, {
          css: { scaleX: 1.5, scaleY: 1.5, opacity: 1 }
        })
        .to(".images-wrapper.top", 1, {
          height: 0
        })
        .to(".product .img-arya .blur", 1, { css: { opacity: 1 } }, "-=1");

      timelineFadingMexA = new TimelineMax();

      timelineFadingMexA
        .to(".fading-mex-section-A .mex-01", 1, {
          y: "-150",
          x: "0",
          ease: Linear.easeNone,
          autoAlpha: 0.3
        })
        .to(".fading-mex-section-A .mex-02", 1, {
          y: "-150",
          x: "0",
          ease: Linear.easeNone,
          autoAlpha: 0.3
        })
        .to(
          ".fading-mex-section-A .mex-01",
          1,
          {
            ease: Linear.easeNone,
            autoAlpha: 0
          },
          "-=1"
        )
        .to(".fading-mex-section-A .mex-03", 1, {
          y: "-150",
          x: "0",
          ease: Linear.easeNone,
          autoAlpha: 0
        })
        .to(
          ".fading-mex-section-A .mex-02",
          1,
          {
            ease: Linear.easeNone,
            autoAlpha: 0
          },
          "-=1"
        );

      timelineFadingMexB = new TimelineMax();

      timelineFadingMexB
        .to(".fading-mex-section-B .mex-01", 1, {
          y: "-150",
          x: "0",
          ease: Linear.easeNone,
          autoAlpha: 0.3
        })
        .to(".fading-mex-section-B .mex-02", 1, {
          y: "-150",
          x: "0",
          ease: Linear.easeNone,
          autoAlpha: 0.3
        })
        .to(
          ".fading-mex-section-B .mex-01",
          1,
          {
            ease: Linear.easeNone,
            autoAlpha: 0
          },
          "-=1"
        )
        .to(".fading-mex-section-B .mex-03", 1, {
          y: "-150",
          x: "0",
          ease: Linear.easeNone,
          autoAlpha: 0
        })
        .to(
          ".fading-mex-section-B .mex-02",
          1,
          {
            ease: Linear.easeNone,
            autoAlpha: 0
          },
          "-=1"
        );

      const scene1 = new ScrollMagic.Scene({
        triggerElement: ".scheda-prodotto",
        triggerHook: 0,
        offset: "0",
        duration: "650"
      })
        .setTween(timeline1)
        .addTo(controller)
        //.addIndicators();

      const scene2 = new ScrollMagic.Scene({
        triggerElement: ".scheda-prodotto",
        triggerHook: 0,
        offset: "800",
        duration: "550"
      })
        .setTween(timeline2)
        .addTo(controller)
        //.addIndicators();

      const scene3 = new ScrollMagic.Scene({
        triggerElement: ".scheda-prodotto",
        triggerHook: 0,
        offset: "1450",
        duration: "600"
      })
        .setTween(timeline3)
        .addTo(controller)
        //.addIndicators();

      const scene4 = new ScrollMagic.Scene({
        triggerElement: ".scheda-prodotto",
        triggerHook: 0,
        offset: "2350",
        duration: "300"
      })
        .setTween(timeline4)
        .addTo(controller)
        //.addIndicators();

      const sceneFadingMexA = new ScrollMagic.Scene({
        triggerElement: ".scheda-prodotto",
        triggerHook: 0,
        offset: "750",
        duration: "450"
      })
        .setTween(timelineFadingMexA)
        .addTo(controller)
        //.addIndicators();

      const sceneFadingMexB = new ScrollMagic.Scene({
        triggerElement: ".scheda-prodotto",
        triggerHook: 0,
        offset: "1550",
        duration: "450"
      })
        .setTween(timelineFadingMexB)
        .addTo(controller)
        //.addIndicators();
    }
    if (
      window.matchMedia(
        "(min-width : 768px) and (max-width : 1024px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio: 2)"
      ).matches
    ) {
      //console.log("****** TP ******");
      //-------------------------------------------------------------------------------------------------------------------------------
      //TABLET PORTRAIT
      //-------------------------------------------------------------------------------------------------------------------------------

      timeline1.kill();
      timeline2.kill();
      timeline3.kill();
      timeline4.kill();
      timelineFadingMexA.kill();
      timelineFadingMexB.kill();

      timeline1 = new TimelineMax();

      timeline1
        .from(".text-block-A", 1, {
          y: "100",
          x: "0",
          ease: Linear.easeNone,
          autoAlpha: 0
        })
        .to(
          ".arya-img-001",
          1,
          {
            scale: 1.4
          },
          "-=1"
        );

      timeline2 = new TimelineMax();
      timeline2
        .to(".text-block-A", 1, {
          y: "-100",
          x: "0",
          ease: Linear.easeNone,
          autoAlpha: 0
        })
        .from(".text-block-B", 1, {
          y: "100",
          x: "0",
          ease: Linear.easeNone,
          autoAlpha: 0
        })
        .to(
          ".arya-img-002",
          1,
          {
            scale: 1.4
          },
          "-=1"
        );

      timeline3 = new TimelineMax();

      timeline3
        .to("#particle-co2", 1, {
          autoAlpha: 1
        })
        .to(".text-block-B", 1, {
          y: "-100",
          x: "0",
          ease: Linear.easeNone,
          autoAlpha: 0
        })
        .from(".text-block-C", 1, {
          y: "100",
          x: "0",
          ease: Linear.easeNone,
          autoAlpha: 0
        })
        .to(
          ".arya-img-003",
          1,
          {
            scale: 1.4
          },
          "-=1"
        );

      timeline4 = new TimelineMax();

      timeline4
        .to(".text-block-C", 1, {
          y: "-100",
          x: "0",
          ease: Linear.easeNone,
          autoAlpha: 0
        })
        .to(".product .img-arya", 1, {
          css: { scaleX: 2.5, scaleY: 2.5, opacity: 1 }
        })
        .to(".images-wrapper.top", 1, {
          height: 0
        })
        .to(".product .img-arya .blur", 1, { css: { opacity: 1 } }, "-=1");

      timelineFadingMexA = new TimelineMax();

      timelineFadingMexA
        .to(".fading-mex-section-A .mex-01", 1, {
          y: "-150",
          x: "0",
          ease: Linear.easeNone,
          autoAlpha: 0.3
        })
        .to(".fading-mex-section-A .mex-02", 1, {
          y: "-150",
          x: "0",
          ease: Linear.easeNone,
          autoAlpha: 0.3
        })
        .to(
          ".fading-mex-section-A .mex-01",
          1,
          {
            ease: Linear.easeNone,
            autoAlpha: 0
          },
          "-=1"
        )
        .to(".fading-mex-section-A .mex-03", 1, {
          y: "-150",
          x: "0",
          ease: Linear.easeNone,
          autoAlpha: 0
        })
        .to(
          ".fading-mex-section-A .mex-02",
          1,
          {
            ease: Linear.easeNone,
            autoAlpha: 0
          },
          "-=1"
        );

      timelineFadingMexB = new TimelineMax();

      timelineFadingMexB
        .to(".fading-mex-section-B .mex-01", 1, {
          y: "-150",
          x: "0",
          ease: Linear.easeNone,
          autoAlpha: 0.3
        })
        .to(".fading-mex-section-B .mex-02", 1, {
          y: "-150",
          x: "0",
          ease: Linear.easeNone,
          autoAlpha: 0.3
        })
        .to(
          ".fading-mex-section-B .mex-01",
          1,
          {
            ease: Linear.easeNone,
            autoAlpha: 0
          },
          "-=1"
        )
        .to(".fading-mex-section-B .mex-03", 1, {
          y: "-150",
          x: "0",
          ease: Linear.easeNone,
          autoAlpha: 0
        })
        .to(
          ".fading-mex-section-B .mex-02",
          1,
          {
            ease: Linear.easeNone,
            autoAlpha: 0
          },
          "-=1"
        );

      const scene1 = new ScrollMagic.Scene({
        triggerElement: ".scheda-prodotto",
        triggerHook: 0,
        offset: "0",
        duration: "400"
      })
        .setTween(timeline1)
        .addTo(controller)
        //.addIndicators();

      const scene2 = new ScrollMagic.Scene({
        triggerElement: ".scheda-prodotto",
        triggerHook: 0,
        offset: "400",
        duration: "400"
      })
        .setTween(timeline2)
        .addTo(controller)
        //.addIndicators();

      const scene3 = new ScrollMagic.Scene({
        triggerElement: ".scheda-prodotto",
        triggerHook: 0,
        offset: "800",
        duration: "400"
      })
        .setTween(timeline3)
        .addTo(controller)
        //.addIndicators();

      const scene4 = new ScrollMagic.Scene({
        triggerElement: ".scheda-prodotto",
        triggerHook: 0,
        offset: "1350",
        duration: "150"
      })
        .setTween(timeline4)
        .addTo(controller)
        //.addIndicators();

      const sceneFadingMexA = new ScrollMagic.Scene({
        triggerElement: ".scheda-prodotto",
        triggerHook: 0,
        offset: "450",
        duration: "400"
      })
        .setTween(timelineFadingMexA)
        .addTo(controller)
        //.addIndicators();

      const sceneFadingMexB = new ScrollMagic.Scene({
        triggerElement: ".scheda-prodotto",
        triggerHook: 0,
        offset: "1100",
        duration: "400"
      })
        .setTween(timelineFadingMexB)
        .addTo(controller)
        //.addIndicators();
    }
    if (
      window.matchMedia(
        "(min-width : 768px) and (max-width : 1024px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio: 2)"
      ).matches
    ) {
      //-------------------------------------------------------------------------------------------------------------------------------
      //TABLET LANDSCAPE IPAD
      //-------------------------------------------------------------------------------------------------------------------------------

      //console.log("****** TL IPAD ******");

      timeline1.kill();
      timeline2.kill();
      timeline3.kill();
      timeline4.kill();
      timelineFadingMexA.kill();
      timelineFadingMexB.kill();

      timeline1 = new TimelineMax();

      timeline1
        .from(".text-block-A", 1, {
          y: "100",
          x: "0",
          ease: Linear.easeNone,
          autoAlpha: 0
        })
        .to(
          ".arya-img-001",
          1,
          {
            scale: 1.4
          },
          "-=1"
        );

      timeline2 = new TimelineMax();
      timeline2
        .to(".text-block-A", 1, {
          y: "-100",
          x: "0",
          ease: Linear.easeNone,
          autoAlpha: 0
        })
        .from(".text-block-B", 1, {
          y: "100",
          x: "0",
          ease: Linear.easeNone,
          autoAlpha: 0
        })
        .to(
          ".arya-img-002",
          1,
          {
            scale: 1.4
          },
          "-=1"
        );

      timeline3 = new TimelineMax();

      timeline3
        .to("#particle-co2", 1, {
          autoAlpha: 1
        })
        .to(".text-block-B", 1, {
          y: "-100",
          x: "0",
          ease: Linear.easeNone,
          autoAlpha: 0
        })
        .from(".text-block-C", 1, {
          y: "100",
          x: "0",
          ease: Linear.easeNone,
          autoAlpha: 0
        })
        .to(
          ".arya-img-003",
          1,
          {
            scale: 1.4
          },
          "-=1"
        );

      timeline4 = new TimelineMax();

      timeline4
        .to(".text-block-C", 1, {
          y: "-100",
          x: "0",
          ease: Linear.easeNone,
          autoAlpha: 0
        })
        .to(".product .img-arya", 1, {
          css: { scaleX: 2.5, scaleY: 2.5, opacity: 1 }
        })
        .to(".images-wrapper.top", 1, {
          height: 0
        })
        .to(".product .img-arya .blur", 1, { css: { opacity: 1 } }, "-=1");

      timelineFadingMexA = new TimelineMax();

      timelineFadingMexA
        .to(".fading-mex-section-A .mex-01", 1, {
          y: "-150",
          x: "0",
          ease: Linear.easeNone,
          autoAlpha: 0.3
        })
        .to(".fading-mex-section-A .mex-02", 1, {
          y: "-150",
          x: "0",
          ease: Linear.easeNone,
          autoAlpha: 0.3
        })
        .to(
          ".fading-mex-section-A .mex-01",
          1,
          {
            ease: Linear.easeNone,
            autoAlpha: 0
          },
          "-=1"
        )
        .to(".fading-mex-section-A .mex-03", 1, {
          y: "-150",
          x: "0",
          ease: Linear.easeNone,
          autoAlpha: 0
        })
        .to(
          ".fading-mex-section-A .mex-02",
          1,
          {
            ease: Linear.easeNone,
            autoAlpha: 0
          },
          "-=1"
        );

      timelineFadingMexB = new TimelineMax();

      timelineFadingMexB
        .to(".fading-mex-section-B .mex-01", 1, {
          y: "-150",
          x: "0",
          ease: Linear.easeNone,
          autoAlpha: 0.3
        })
        .to(".fading-mex-section-B .mex-02", 1, {
          y: "-150",
          x: "0",
          ease: Linear.easeNone,
          autoAlpha: 0.3
        })
        .to(
          ".fading-mex-section-B .mex-01",
          1,
          {
            ease: Linear.easeNone,
            autoAlpha: 0
          },
          "-=1"
        )
        .to(".fading-mex-section-B .mex-03", 1, {
          y: "-150",
          x: "0",
          ease: Linear.easeNone,
          autoAlpha: 0
        })
        .to(
          ".fading-mex-section-B .mex-02",
          1,
          {
            ease: Linear.easeNone,
            autoAlpha: 0
          },
          "-=1"
        );

      const scene1 = new ScrollMagic.Scene({
        triggerElement: ".scheda-prodotto",
        triggerHook: 0,
        offset: "0",
        duration: "300"
      })
        .setTween(timeline1)
        .addTo(controller)
        //.addIndicators();

      const scene2 = new ScrollMagic.Scene({
        triggerElement: ".scheda-prodotto",
        triggerHook: 0,
        offset: "350",
        duration: "450"
      })
        .setTween(timeline2)
        .addTo(controller)
        //.addIndicators();

      const scene3 = new ScrollMagic.Scene({
        triggerElement: ".scheda-prodotto",
        triggerHook: 0,
        offset: "850",
        duration: "550"
      })
        .setTween(timeline3)
        .addTo(controller)
        //.addIndicators();

      const scene4 = new ScrollMagic.Scene({
        triggerElement: ".scheda-prodotto",
        triggerHook: 0,
        offset: "1450",
        duration: "550"
      })
        .setTween(timeline4)
        .addTo(controller)
        //.addIndicators();

      const sceneFadingMexA = new ScrollMagic.Scene({
        triggerElement: ".scheda-prodotto",
        triggerHook: 0,
        offset: "750",
        duration: "350"
      })
        .setTween(timelineFadingMexA)
        .addTo(controller)
        //.addIndicators();

      const sceneFadingMexB = new ScrollMagic.Scene({
        triggerElement: ".scheda-prodotto",
        triggerHook: 0,
        offset: "1300",
        duration: "400"
      })
        .setTween(timelineFadingMexB)
        .addTo(controller)
        //.addIndicators();
    }

    if (window.matchMedia("(min-width: 1025px)").matches) {
      //-------------------------------------------------------------------------------------------------------------------------------
      //DESKTOP TIMELINE
      //-------------------------------------------------------------------------------------------------------------------------------

      //console.log("****** DESKTOP ******");

      timeline1.kill();
      timeline2.kill();
      timeline3.kill();
      timeline4.kill();
      timelineFadingMexA.kill();
      timelineFadingMexB.kill();

      timeline1 = new TimelineMax();

      timeline1
        .from(".text-block-A", 1, {
          y: "100",
          x: "0",
          ease: Linear.easeNone,
          autoAlpha: 0
        })
        .to(
          ".arya-img-001",
          1,
          {
            scale: 1.4
          },
          "-=1"
        );

      timeline2 = new TimelineMax();
      timeline2
        .to(".text-block-A", 1, {
          y: "-100",
          x: "0",
          ease: Linear.easeNone,
          autoAlpha: 0
        })
        .from(".text-block-B", 1, {
          y: "100",
          x: "0",
          ease: Linear.easeNone,
          autoAlpha: 0
        })
        .to(
          ".arya-img-002",
          1,
          {
            scale: 1.4
          },
          "-=1"
        );

      timeline3 = new TimelineMax();

      timeline3
        .to("#particle-co2", 1, {
          autoAlpha: 1
        })
        .to(".text-block-B", 1, {
          y: "-100",
          x: "0",
          ease: Linear.easeNone,
          autoAlpha: 0
        })
        .from(".text-block-C", 1, {
          y: "100",
          x: "0",
          ease: Linear.easeNone,
          autoAlpha: 0
        })
        .to(
          ".arya-img-003",
          1,
          {
            scale: 1.4
          },
          "-=1"
        );

      timeline4 = new TimelineMax();

      timeline4
        .to(".text-block-C", 1, {
          y: "-100",
          x: "0",
          ease: Linear.easeNone,
          autoAlpha: 0
        })
        .to(".product .img-arya", 1, {
          css: { scaleX: 2.5, scaleY: 2.5, opacity: 1 }
        })
        .to(".images-wrapper.top", 1, {
          height: 0
        })
        .to(".product .img-arya .blur", 1, { css: { opacity: 1 } }, "-=1");

      timelineFadingMexA = new TimelineMax();

      timelineFadingMexA
        .to(".fading-mex-section-A .mex-01", 1, {
          y: "-150",
          x: "0",
          ease: Linear.easeNone,
          autoAlpha: 0.3
        })
        .to(".fading-mex-section-A .mex-02", 1, {
          y: "-150",
          x: "0",
          ease: Linear.easeNone,
          autoAlpha: 0.3
        })
        .to(
          ".fading-mex-section-A .mex-01",
          1,
          {
            ease: Linear.easeNone,
            autoAlpha: 0
          },
          "-=1"
        )
        .to(".fading-mex-section-A .mex-03", 1, {
          y: "-150",
          x: "0",
          ease: Linear.easeNone,
          autoAlpha: 0
        })
        .to(
          ".fading-mex-section-A .mex-02",
          1,
          {
            ease: Linear.easeNone,
            autoAlpha: 0
          },
          "-=1"
        );

      timelineFadingMexB = new TimelineMax();

      timelineFadingMexB
        .to(".fading-mex-section-B .mex-01", 1, {
          y: "-150",
          x: "0",
          ease: Linear.easeNone,
          autoAlpha: 0.3
        })
        .to(".fading-mex-section-B .mex-02", 1, {
          y: "-150",
          x: "0",
          ease: Linear.easeNone,
          autoAlpha: 0.3
        })
        .to(
          ".fading-mex-section-B .mex-01",
          1,
          {
            ease: Linear.easeNone,
            autoAlpha: 0
          },
          "-=1"
        )
        .to(".fading-mex-section-B .mex-03", 1, {
          y: "-150",
          x: "0",
          ease: Linear.easeNone,
          autoAlpha: 0
        })
        .to(
          ".fading-mex-section-B .mex-02",
          1,
          {
            ease: Linear.easeNone,
            autoAlpha: 0
          },
          "-=1"
        );

      const scene1 = new ScrollMagic.Scene({
        triggerElement: ".scheda-prodotto",
        triggerHook: 0,
        offset: "0",
        duration: "350"
      })
        .setTween(timeline1)
        .addTo(controller)
        //.addIndicators();

      const scene2 = new ScrollMagic.Scene({
        triggerElement: ".scheda-prodotto",
        triggerHook: 0,
        offset: "450",
        duration: "600"
      })
        .setTween(timeline2)
        .addTo(controller)
        //.addIndicators();

      const scene3 = new ScrollMagic.Scene({
        triggerElement: ".scheda-prodotto",
        triggerHook: 0,
        offset: "1200",
        duration: "600"
      })
        .setTween(timeline3)
        .addTo(controller)
        //.addIndicators();

      const scene4 = new ScrollMagic.Scene({
        triggerElement: ".scheda-prodotto",
        triggerHook: 0,
        offset: "2150",
        duration: "550"
      })
        .setTween(timeline4)
        .addTo(controller)
        //.addIndicators();

      const sceneFadingMexA = new ScrollMagic.Scene({
        triggerElement: ".scheda-prodotto",
        triggerHook: 0,
        offset: "1150",
        duration: "400"
      })
        .setTween(timelineFadingMexA)
        .addTo(controller)
        //.addIndicators();

      const sceneFadingMexB = new ScrollMagic.Scene({
        triggerElement: ".scheda-prodotto",
        triggerHook: 0,
        offset: "1850",
        duration: "400"
      })
        .setTween(timelineFadingMexB)
        .addTo(controller)
        //.addIndicators();
    }

    setTimeout(function() {
      if ($("#particles-js")) {
        particlesJS("particles-js", {
          particles: {
            number: {
              value: 10,
              density: {
                enable: true,
                value_area: 600
              }
            },
            color: {
              value: "#85cee4"
            },
            shape: {
              type: "circle",
              stroke: {
                width: 0,
                color: "#000"
              },
              polygon: {
                nb_sides: 3
              }
            },
            opacity: {
              value: 0.4890406785639845,
              random: true,
              anim: {
                enable: true,
                speed: 0.08120772123013452,
                opacity_min: 0.09744926547616141,
                sync: false
              }
            },
            size: {
              value: 32.06824121731046,
              random: true,
              anim: {
                enable: true,
                speed: 12.181158184520175,
                size_min: 5.684540486109416,
                sync: false
              }
            },
            line_linked: {
              enable: false,
              distance: 689.4671861721748,
              color: "#ffffff",
              opacity: 0.70550130678083,
              width: 1.763753266952075
            },
            move: {
              enable: true,
              speed: 8,
              direction: "none",
              random: false,
              straight: false,
              out_mode: "out",
              bounce: false,
              attract: {
                enable: false,
                rotateX: 600,
                rotateY: 1200
              }
            }
          },
          retina_detect: true
        });
      }

      if ($("#particle-co2")) {
        particlesJS("particle-co2", {
          particles: {
            number: {
              value: 5,
              density: {
                enable: true,
                value_area: 500
              }
            },
            color: {
              value: "#1b1e34"
            },
            shape: {
              type: "image",
              image: {
                src: "../assets/Jeniot/images/ico-co2.png",
                width: 42,
                height: 22
              }
            },
            opacity: {
              value: 0.4890406785639845,
              random: true,
              anim: {
                enable: true,
                speed: 0.08120772123013452,
                opacity_min: 0.09744926547616141,
                sync: false
              }
            },
            size: {
              value: 32.06824121731046,
              random: true,
              anim: {
                enable: true,
                speed: 12.181158184520175,
                size_min: 5.684540486109416,
                sync: false
              }
            },
            move: {
              enable: true,
              speed: 8,
              direction: "right",
              random: true,
              straight: false,
              out_mode: "out",
              bounce: false,
              attract: {
                enable: false,
                rotateX: 600,
                rotateY: 1200
              }
            }
          },
          retina_detect: true
        });
      }
    }, 50);
  }

  const pageStandardWidth = 1440;

  function setZoomOfMainAnimation() {
    if (window.matchMedia("(min-width: 1025px)").matches) {
      var ow = $(window).width();
      var zc = ow / pageStandardWidth;
      $(".svg-animations").css("zoom", zc);

      //$(".animation-wrapper").css("zoom", zc);
    }
    if (window.matchMedia("(min-width: 768px) and (max-width: 1024px)").matches) {
        var ow = $(window).width();
        var zc = ow / pageStandardWidth;
        $(".animation-wrapper").css("zoom", zc);
    }
  }

  //   mqMO.addListener(createTimeline);
  //   mqTP.addListener(createTimeline);
  //   mqTL.addListener(createTimeline);
  //   mqDE.addListener(createTimeline);
  //addListener(createTimeline);

  createTimeline();

  setZoomOfMainAnimation();
  // chooseTween();

  window.onresize = function(event) {
    setTimeout(function() {
      //chooseTween();
      createTimeline();
      setZoomOfMainAnimation();
    }, 50);
  };

  //   if(video){
  //     preloadVideo(video, startScrollAnimation);
  //   }

  $(".animation-wrapper").show();
});
