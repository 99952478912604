// Start Module: Header
(function() {
  //let header = window.generali.header || {};

  let header = document.querySelector("header.header");
  //-console.log('modules/mod-header-menu.js has been included');

  let htmlAndBody = document.querySelector("html, body");
  let headerContent = document.querySelector("header .header--content");

  let navPrivateTrigger = document.querySelector("#navPrivateTrigger");
  let navBusinessTrigger = document.querySelector("#navBusinessTrigger");
  let navPrivate, navBusiness;
  let menuTriggers = document.querySelectorAll("header .menu-trigger");

  //-console.log("menu-trigger", menuTriggers);

  let megamenuDesktop = document.querySelector("#megamenuDesktop");

  let navPrivateMobileTrigger = document.querySelector(
    "#navPrivateMobileTrigger"
  );
  let navBusinessMobileTrigger = document.querySelector(
    "#navBusinessMobileTrigger"
  );
  let navPrivateMobile, navBusinessMobile;

  let menuTriggerMobile = document.querySelector("#menuTriggerMobile");
  let megamenuMobile = document.querySelector("#megamenuMobile");
  let megamenuMobileNav = megamenuMobile
    ? megamenuMobile.querySelector(".megamenu-mobile--nav")
    : undefined;

  let megamenuMobileContainer = megamenuMobile
    ? megamenuMobile.querySelector(".megamenu-mobile--container")
    : undefined;
  let megamenuMobileContainerSub = megamenuMobile
    ? megamenuMobile.querySelector(".megamenu-mobile--container-sub")
    : undefined;

  // let megamenuMobileTriggers = megamenuMobileNav
  //   ? megamenuMobileNav.querySelectorAll(".menu-trigger-mobile")
  //   : undefined;

  let megamenuMobileTriggers = megamenuMobile.querySelectorAll(
    ".menu-trigger-mobile"
  );
  let megamenuMobileTriggersSub = megamenuMobile.querySelectorAll(
    ".menu-trigger-mobile-sub"
  );

  let megamenuMobileBack = megamenuMobileContainer
    ? megamenuMobileContainer.querySelector(".megamenu-mobile--title")
    : undefined;
  let megamenuMobileBackArrow = megamenuMobileContainer
    ? megamenuMobileContainer.querySelector(
        ".megamenu-mobile--title .icon-back"
      )
    : undefined;
  let megamenuMobileBackTitle = megamenuMobileBack
    ? megamenuMobileBack.querySelector(".menu-title")
    : undefined;

  let megamenuMobileBackSub = megamenuMobileContainerSub
    ? megamenuMobileContainerSub.querySelector(".megamenu-mobile--title")
    : undefined;
  let megamenuMobileBackTitleSub = megamenuMobileBackSub
    ? megamenuMobileBackSub.querySelector(".menu-title")
    : undefined;
  let megamenuMobileBackSubArrow = megamenuMobileContainerSub
    ? megamenuMobileContainerSub.querySelector(
        ".megamenu-mobile--title .icon-back"
      )
    : undefined;

  let navbarToggler = document.querySelectorAll(".navbar-toggler");
  let subMenuCloseIcon = document.querySelectorAll(
    ".megamenu-mobile--title .icon-close"
  );

  //header.init = () => {
  // if (navPrivateTrigger) {

  //   //-console.log("navPrivateTrigger");

  //   if (navPrivateTrigger.dataset.target) navPrivate = document.querySelector(navPrivateTrigger.dataset.target);

  //   navPrivateTrigger.addEventListener("click", event => {
  //     navPrivateTrigger.classList.add("active");
  //     if (navBusinessTrigger) navBusinessTrigger.classList.remove("active");

  //     if (navPrivate) navPrivate.classList.remove("hidden");
  //     if (navBusiness) navBusiness.classList.add("hidden");

  //     if ($("#megamenuDesktop").hasClass("menu-open")) {
  //       $("#megamenuDesktop").removeClass("menu-open");
  //       $(".menu-trigger").removeClass("active");
  //       $(".header--content").removeClass("menu-open");
  //     }
  //   });
  // }

  // if (navBusinessTrigger) {

  //   //-console.log("navBusinessTrigger");

  //   if (navBusinessTrigger.dataset.target) navBusiness = document.querySelector(navBusinessTrigger.dataset.target);

  //   navBusinessTrigger.addEventListener("click", event => {
  //     if (navPrivateTrigger) navPrivateTrigger.classList.remove("active");
  //     navBusinessTrigger.classList.add("active");

  //     if (navPrivate) navPrivate.classList.add("hidden");
  //     if (navBusiness) navBusiness.classList.remove("hidden");

  //     if ($("#megamenuDesktop").hasClass("menu-open")) {
  //       $("#megamenuDesktop").removeClass("menu-open");
  //       $(".menu-trigger").removeClass("active");
  //       $(".header--content").removeClass("menu-open");
  //     }
  //   });
  // }

  Array.from(menuTriggers).forEach(menuTrigger => {
    menuTrigger.addEventListener("click", event => {
      let menu = menuTrigger.dataset.target
        ? document.querySelector(menuTrigger.dataset.target)
        : undefined;

      if (menuTrigger.classList.contains("active")) {
        //btn.parentNode.classList.add("active");

        //-console.log("menuTriggerrreeddAA");

        // Hide this menu
        menuTrigger.classList.remove("active");
        if (megamenuDesktop) megamenuDesktop.classList.remove("menu-open");
        if (menu) menu.classList.add("hidden");
        if (headerContent) headerContent.classList.remove("menu-open");
        htmlAndBody.classList.remove("menu-open");
        htmlAndBody.classList.remove("overflow-hidden");
      } else {
        // Hide current menu

        //-console.log("menuTriggerrreeddBB");

        Array.from(menuTriggers).forEach(trigger => {
          let menu = trigger.dataset.target
            ? document.querySelector(trigger.dataset.target)
            : undefined;

          trigger.classList.remove("active");
          if (menu) menu.classList.add("hidden");
        });

        // Show this menu
        menuTrigger.classList.add("active");
        if (megamenuDesktop) megamenuDesktop.classList.add("menu-open");
        if (menu) menu.classList.remove("hidden");
        if (headerContent) headerContent.classList.add("menu-open");
        htmlAndBody.classList.add("menu-open");
        htmlAndBody.classList.add("overflow-hidden");
      }
    });
  });

  // if (navPrivateMobileTrigger) {
  //   if (navPrivateMobileTrigger.dataset.target)
  //     navPrivateMobile = document.querySelector(navPrivateMobileTrigger.dataset.target);

  //   navPrivateMobileTrigger.addEventListener("click", event => {
  //     navPrivateMobileTrigger.classList.add("active");
  //     if (navBusinessMobileTrigger) navBusinessMobileTrigger.classList.remove("active");

  //     if (navPrivateMobile) navPrivateMobile.classList.remove("hidden");
  //     if (navBusinessMobile) navBusinessMobile.classList.add("hidden");
  //   });
  // }

  // if (navBusinessMobileTrigger) {
  //   if (navBusinessMobileTrigger.dataset.target)
  //     navBusinessMobile = document.querySelector(navBusinessMobileTrigger.dataset.target);

  //   navBusinessMobileTrigger.addEventListener("click", event => {
  //     if (navPrivateMobileTrigger) navPrivateMobileTrigger.classList.remove("active");
  //     navBusinessMobileTrigger.classList.add("active");

  //     if (navPrivateMobile) navPrivateMobile.classList.add("hidden");
  //     if (navBusinessMobile) navBusinessMobile.classList.remove("hidden");
  //   });
  // }

  if (menuTriggerMobile) {
    menuTriggerMobile.addEventListener("click", event => {
      if (menuTriggerMobile.classList.contains("active")) {
        //-console.log("trigger Mobile A")
        menuTriggerMobile.classList.remove("active");
        if (headerContent) headerContent.classList.remove("menu-open");
        if (megamenuMobile) megamenuMobile.classList.remove("menu-open");
        htmlAndBody.classList.remove("menu-open");
        htmlAndBody.classList.remove("overflow-hidden");
      } else {
        //-console.log("trigger Mobile B")
        menuTriggerMobile.classList.add("active");
        if (headerContent) headerContent.classList.add("menu-open");
        if (megamenuMobile) megamenuMobile.classList.add("menu-open");
        htmlAndBody.classList.add("menu-open");
        htmlAndBody.classList.add("overflow-hidden");
      }
    });
  }

  if (megamenuMobileTriggers) {
    Array.from(megamenuMobileTriggers).forEach(megamenuMobileTrigger => {
      megamenuMobileTrigger.addEventListener("click", event => {
        // Hide current menu
        //-console.log("stepA")

        Array.from(megamenuMobileTriggers).forEach(trigger => {
          //-console.log("---stepB")
          let menu = trigger.dataset.target
            ? document.querySelector(trigger.dataset.target)
            : undefined;
          if (menu) menu.classList.add("hidden");
          //-console.log("---stepB--menu--->", menu)
        });

        // Show this menu
        let menu = megamenuMobileTrigger.dataset.target
          ? document.querySelector(megamenuMobileTrigger.dataset.target)
          : undefined;

        if (megamenuMobileBackTitle)
          megamenuMobileBackTitle.innerHTML = megamenuMobileTrigger.title.length
            ? megamenuMobileTrigger.title
            : "Back";

        if (menu) menu.classList.remove("hidden");

        if (megamenuMobileContainer)
          megamenuMobileContainer.classList.remove("hidden");
        if (megamenuMobileContainerSub)
          megamenuMobileContainerSub.classList.add("hidden");
        //megamenuMobileContainer.classList.remove("hidden");

        if (megamenuMobileNav) megamenuMobileNav.classList.add("hidden");
      });
    });
  }

  if (megamenuMobileBackArrow) {
    megamenuMobileBackArrow.addEventListener("click", event => {
      if (megamenuMobileContainer)
        megamenuMobileContainer.classList.add("hidden");
      if (megamenuMobileContainerSub)
        megamenuMobileContainerSub.classList.add("hidden");

      if (megamenuMobileNav) megamenuMobileNav.classList.remove("hidden");
    });
  }

  if (megamenuMobileTriggersSub) {
    Array.from(megamenuMobileTriggersSub).forEach(megamenuMobileTriggerSub => {
      megamenuMobileTriggerSub.addEventListener("click", event => {
        // Hide current menu
        //-console.log("stepA")

        Array.from(megamenuMobileTriggersSub).forEach(trigger => {
          //-console.log("---stepB")
          let menu = trigger.dataset.target
            ? document.querySelector(trigger.dataset.target)
            : undefined;
          if (menu) menu.classList.add("hidden");
          //-console.log("---stepB--menu--->", menu)
        });

        // Show this menu
        let menu = megamenuMobileTriggerSub.dataset.target
          ? document.querySelector(megamenuMobileTriggerSub.dataset.target)
          : undefined;

        if (megamenuMobileBackTitleSub)
          if (megamenuMobileBackTitleSub)
            //-console.log("stepC--ShowMenu--->", menu , megamenuMobileTriggerSub.title)
            megamenuMobileBackTitleSub.innerHTML = megamenuMobileTriggerSub
              .title.length
              ? megamenuMobileTriggerSub.title
              : "Back";

        if (menu) menu.classList.remove("hidden");

        if (megamenuMobileContainerSub)
          megamenuMobileContainerSub.classList.remove("hidden");
        if (megamenuMobileContainer)
          megamenuMobileContainer.classList.add("hidden");

        if (megamenuMobileNav) megamenuMobileNav.classList.add("hidden");
      });
    });
  }

  if (megamenuMobileBackSubArrow) {
    megamenuMobileBackSubArrow.addEventListener("click", event => {
      if (megamenuMobileContainerSub)
        megamenuMobileContainerSub.classList.add("hidden");
      megamenuMobileContainer.classList.remove("hidden");
      if (megamenuMobileNav) megamenuMobileNav.classList.remove("hidden");
    });
  }

  $(navbarToggler).on("click", function() {
    if ($(megamenuMobile).hasClass("menu-open")) {
      $(megamenuMobile).removeClass("menu-open");
      $(navbarToggler).removeClass("active");
    } else {
      $(megamenuMobile).addClass("menu-open");
      $(navbarToggler).addClass("active");
      $(megamenuMobileContainer).addClass("hidden");
      $(megamenuMobileContainerSub).addClass("hidden");
      $(megamenuMobileNav).removeClass("hidden");
    }

    // if ($(menuTriggerMobile).hasClass("active")) {
    //   //-console.log("trigger Mobile A")
    //   $(menuTriggerMobile).removeClass("active");
    //   if ($(headerContent)) $(headerContent).removeClass("menu-open");
    //   if ($(megamenuMobile)) $(megamenuMobile).removeClass("menu-open");
    //   $(htmlAndBody).removeClass("menu-open");
    //   $(htmlAndBody).removeClass("overflow-hidden");
    //   $(navbarToggler).removeClass("active");

    // } else {
    //   $(menuTriggerMobile).addClass("active");
    //   if ($(headerContent)) $(headerContent).addClass("menu-open");
    //   if ($(megamenuMobile)) $(megamenuMobile).addClass("menu-open");
    //   $(htmlAndBody).addClass("menu-open");
    //   $(htmlAndBody).addClass("overflow-hidden");
    //   $(navbarToggler).addClass("active");
    // }
  });
  $(subMenuCloseIcon).on("click", function() {
    if ($(megamenuMobileContainer).hasClass("hidden")) {
    } else {
      $(megamenuMobileContainer).addClass("hidden");
      $(megamenuMobileContainerSub).addClass("hidden");
      $(megamenuMobileNav).removeClass("hidden");
    }

    if ($(megamenuMobile).hasClass("menu-open")) {
      $(megamenuMobile).removeClass("menu-open");
      $(navbarToggler).removeClass("active");
    }
  });

  // Close megamenu when click on overlay
  $(".megamenu-desktop--overlay , .closemm").on("click", function() {
    if ($("#megamenuDesktop").hasClass("menu-open")) {
      $("#megamenuDesktop").removeClass("menu-open");
      $(".menu-trigger").removeClass("active");
      $(".header--content").removeClass("menu-open");
      $("html").removeClass("overflow-hidden");
    }
  });
  // END - Close megamenu when click on overlay

  // START .gen-banner fadeOut
  if ($(".gen-banner").length) {
    // fadeOut when scroll
    $(window).scroll(function() {
      if ($(this).scrollTop() > 200) {
        $(".gen-banner").fadeOut(250);
      }
    });
    // fadeOut when click
    $(".gen-banner")
      .find(".close-banner")
      .on("click", function() {
        $(this)
          .closest(".gen-banner")
          .fadeOut(250);
      });
    $(".gen-banner")
      .find(".agree a")
      .on("click", function() {
        $(this)
          .closest(".gen-banner")
          .fadeOut(250);
      });
  }
  // END .gen-banner fadeOut

  let hideThingsOnMq = function() {
    if (window.matchMedia("(min-width: 990px)").matches) {
      //-console.log(">=990px")
      if ($(megamenuMobile).hasClass("menu-open")) {
        $(megamenuMobile).removeClass("menu-open");
        $(navbarToggler).removeClass("active");
      }
    } else {
      //-console.log("<990px")
      if ($("#megamenuDesktop").hasClass("menu-open")) {
        $("#megamenuDesktop").removeClass("menu-open");
        $(".menu-trigger").removeClass("active");
        $(".header--content").removeClass("menu-open");
        $("html").removeClass("overflow-hidden");
      }
    }
  };

  $(window).resize(function() {
    hideThingsOnMq();
  });

  //header.smartBannerCalcHeight();
  //};

  // header.smartBannerCalcHeight = () => {
  //   // Calculate height of smart banner and header for megamenu 'top'
  //   let smartBannerDarkHeight = $(".smart-banner.sb-dark").outerHeight() || 0;
  //   let smartBannerLightHeight = $(".smart-banner.sb-light").outerHeight() || 0;
  //   let smartBannerGeneraliHeight = $(".smart-banner.sb-generali").outerHeight() || 0;
  //   let smartBannerAgencyHeight = $(".smart-banner.sb-agency").outerHeight() || 0;
  //   let headerHeight = $("header").outerHeight();
  //   let smartBannerTotHeight =
  //     smartBannerDarkHeight +
  //     smartBannerLightHeight +
  //     smartBannerGeneraliHeight +
  //     smartBannerAgencyHeight +
  //     headerHeight;

  //   if ($(".megamenu-desktop").length || $(".megamenu-mobile").length) {
  //     $(".megamenu-desktop").css("top", smartBannerTotHeight);
  //     $(".megamenu-mobile").css("top", smartBannerTotHeight);

  //     let windowOffsetPosition = 0;
  //     window.addEventListener("scroll", function() {
  //       let smartBannerDarkHeight = $(".smart-banner.sb-dark").outerHeight() || 0;
  //       let smartBannerLightHeight = $(".smart-banner.sb-light").outerHeight() || 0;
  //       let smartBannerGeneraliHeight = $(".smart-banner.sb-generali").outerHeight() || 0;
  //       let smartBannerAgencyHeight = $(".smart-banner.sb-agency").outerHeight() || 0;
  //       let headerHeight = $("header").outerHeight();
  //       let smartBannerTotHeight =
  //         smartBannerDarkHeight +
  //         smartBannerLightHeight +
  //         smartBannerGeneraliHeight +
  //         smartBannerAgencyHeight +
  //         headerHeight;
  //       let windowOffsetPosition = window.pageYOffset;
  //       $(".megamenu-mobile").css("top", smartBannerTotHeight - windowOffsetPosition);
  //     });

  //     // slideUp .smart-banner
  //     $(".smart-banner .close-banner").on("click", function(e) {
  //       e.preventDefault();
  //       $(this)
  //         .closest(".smart-banner")
  //         .slideUp("500", function() {
  //           $(this).addClass("smart-banner-closed");

  //           let smartBannerDarkHeight = $(".smart-banner.sb-dark").outerHeight() || 0;
  //           let smartBannerLightHeight = $(".smart-banner.sb-light").outerHeight() || 0;
  //           let smartBannerGeneraliHeight = $(".smart-banner.sb-generali").outerHeight() || 0;
  //           let smartBannerAgencyHeight = $(".smart-banner.sb-agency").outerHeight() || 0;
  //           let headerHeight = $("header").outerHeight();
  //           let smartBannerTotHeight =
  //             smartBannerDarkHeight +
  //             smartBannerLightHeight +
  //             smartBannerGeneraliHeight +
  //             smartBannerAgencyHeight +
  //             headerHeight;
  //           $(".megamenu-desktop").css("top", smartBannerTotHeight);
  //           $(".megamenu-mobile").css("top", smartBannerTotHeight - window.pageYOffset);
  //         });
  //     });
  //     // END - slideUp .smart-banner
  //   }
  //   // END - Calculate height of smart banner and header for megamenu 'top'
  // };

  //window.addEventListener("resize", header.smartBannerCalcHeight);

  //window.generali.header = header;
})();
// End Module: Header
