//////////////////////
// VIEWPORT CHECKER //
//////////////////////
var isMobile = false;
var isTabletPort = false;
var isTabletLand = false;
var isDesktop = false;

function viewportChecker() {
  var windowWidht = $(window).width();

  if (windowWidht < 768) {
    isMobile = true;
    isTabletPort = false;
    isTabletLand = false;
    isDesktop = false;
  }
  if (windowWidht >= 768 && windowWidht < 990) {
    isMobile = false;
    isTabletPort = true;
    isTabletLand = false;
    isDesktop = false;
  }
  if (windowWidht >= 990 && windowWidht < 1400) {
    isMobile = false;
    isTabletPort = false;
    isTabletLand = true;
    isDesktop = false;
  }
  if (windowWidht >= 1400) {
    isMobile = false;
    isTabletPort = false;
    isTabletLand = false;
    isDesktop = true;
  }

}



