$( document ).ready(function() {

        $(".job-offers .jobsearch").keyup(function () {
          var searchTerm = $(".job-offers .jobsearch").val();
          var listItem = $('.results tbody').children('tr');
          var searchSplit = searchTerm.replace(/ /g, "'):containsi('")
          
        $.extend($.expr[':'], {'containsi': function(elem, i, match, array){
              return (elem.textContent || elem.innerText || '').toLowerCase().indexOf((match[3] || "").toLowerCase()) >= 0;
          }
        });
          
        $(".job-offers .results tbody tr").not(":containsi('" + searchSplit + "')").each(function(e){
          $(this).attr('visible','false');
        });
      
        $(".job-offers .results tbody tr:containsi('" + searchSplit + "')").each(function(e){
          $(this).attr('visible','true');
        });
      
        var jobCount = $('.job-offers .results tbody tr[visible="true"]').length;
          $('.counter').text(jobCount + ' item');
      
        
        if (window.matchMedia("(min-width: 990px)").matches) {
        //-console.log(">=990px")
            if(jobCount == '0') 
            {
                $('.job-offers .no-result').show(); 
                $('.job-offers .job-offers-content table.results thead').hide(); 
                $('.job-offers .job-offers-actions').hide();
            }
            else {
                $('.job-offers .no-result').hide();
                $('.job-offers .job-offers-content table.results thead').show();
                $('.job-offers .job-offers-actions').show();
            }
        } else {
        //-console.log("<990px")
            if(jobCount == '0') 
            {
                $('.job-offers .no-result').show(); 
                $('.job-offers .job-offers-actions').hide();
            }
            else {
                $('.job-offers .no-result').hide();
                $('.job-offers .job-offers-actions').show();
            }
        }
    });

        //---------------------------------------------
        //---------------------------------------------


        $('.input-file').each(function() {
            var $input = $(this),
                $label = $input.next('.js-labelFile'),
                labelVal = $label.html();
            
            $input.on('change', function(element) {
                var fileName = '';
                if (element.target.value) fileName = element.target.value.split('\\').pop();
                fileName ? $label.addClass('has-file').find('.js-fileName').html(fileName) : $label.removeClass('has-file').html(labelVal);
            });
        });

});
